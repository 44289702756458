import React, { useEffect, useState } from "react";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import TextInputField from "../../Components/TextInputField";
import TextArea from "../../Components/TextArea";
import "../Pages.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FormQualityTesting = (props) => {
  const initialProjectData = {
    departmentName: "",
    azureProjectName: "",
    // monthCycle: "",
    // year: new Date(),
    monthCycle: { value: "Oct-Dec", label: "Oct-Dec" },
    year: new Date(2023, 0, 1),
    bugDetectionPhase: { value: "UAT", label: "UAT" },
    numberOfBug: "",
    rating: "",
    comment: "",
  };
  const [projectData, setProjectData] = useState(initialProjectData);
  const [loading, setLoading] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [showValidation, setShowValidation] = useState({
    departmentNameError: null,
    azureProjectNameError: null,
    // monthCycleError: null,
    // bugDetectionPhaseError: null,
    commentError: null,
  });

  const handleProjectData = (val, key) => {
    setProjectData((previousData) => {
      return {
        ...previousData,
        [key]: val,
      };
    });
    setShowValidation((previousData) => {
      return {
        ...previousData,
        [`${key}Error`]: val ? false : true,
      };
    });
  };

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
        ]);
        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;
          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });
          setMasterData({
            ...{ departments },
          });
        }
      } catch (error) {}
    };
    getDepartments();
  }, []);

  useEffect(() => {
    const getProjects = async () => {
      let projectList = [];
      if (projectData.departmentName) {
        const departmentName = projectData.departmentName.label;
        try {
          const resp = await callAPI(
            "GET",
            apiEndPoints.getProjName + "?departmentName=" + departmentName
          );
          projectList = resp.data.data;
          projectList.forEach((item) => {
            item.value = item.azureProjectId;
            item.label = item.azureProjectName;
            delete item.azureProjectId;
            delete item.project;
            delete item.department;
          });
        } catch (err) {
          console.log("Error in fetching Project Names: ", err);
        }
      }
      setProjectList(projectList);
    };
    getProjects();
  }, [projectData.departmentName]);

  useEffect(() => {
    props.fetchQualityTesting();
  }, []);

  useEffect(() => {
    const fetchBugsAndRating = async () => {
      const { azureProjectName, monthCycle, year } = projectData;
      try {
        setLoading(true);
        const bugResponse = await axios.post(
          `${apiEndPoints.getBugCount}?AzureProjectId=${
            azureProjectName.value
          }&quarter=${monthCycle.value}&year=${year.getFullYear()}`
        );
        const bugCount = bugResponse.data;
        if (bugCount) {
          setProjectData((prevData) => ({
            ...prevData,
            numberOfBug: bugCount.numberOfBug,
          }));
          setShowValidation((previousData) => ({
            ...previousData,
            numberOfBugError: false,
          }));

          const ratingResponse = await axios.post(
            `${apiEndPoints.getRating}?numberOfBug=${bugCount.numberOfBug}`
          );

          const ratingData = ratingResponse.data;
          setProjectData((prevData) => ({
            ...prevData,
            rating: ratingData.rating,
          }));
          setShowValidation((previousData) => ({
            ...previousData,
            ratingError: false,
          }));
        }
      } catch (error) {
        console.error("Error fetching Bugs or Rating:", error);
      } finally {
        setLoading(false);
      }
    };
    if (
      projectData.azureProjectName &&
      projectData.monthCycle &&
      projectData.year
    ) {
      fetchBugsAndRating();
    }
  }, [projectData.azureProjectName, projectData.monthCycle, projectData.year]);

  const clearFormFields = () => {
    setProjectData(initialProjectData);
    setShowValidation(false);
  };
  const clearFormFieldsOnAdd = () => {
    setProjectData({
      departmentName: projectData.departmentName,
      azureProjectName: "",
      monthCycle: projectData.monthCycle,
      year: new Date(2023, 0, 1),
      bugDetectionPhase: projectData.bugDetectionPhase,
      numberOfBug: "",
      rating: "",
      comment: "",
    });

    setShowValidation((prev) => {
      return {
        ...prev,
        azureProjectNameError: null,
        // bugDetectionPhaseError: null,
        commentError: null,
      };
    });
  };

  const validationHandler = () => {
    const data = Object.values(showValidation).every((item) => item === false);
    const data1 = { ...projectData };
    const data2 = { ...showValidation };

    for (const key in data1) {
      if (data1[key] === "") {
        data2[`${key}Error`] = true;
      }
    }
    setShowValidation(data2);
    return data;
  };

  const handleSubmit = async () => {
    if (validationHandler() === false) {
      return false;
    }
    try {
      const employeeName = sessionStorage.getItem("employeeName");
      const response = await axios.post(
        apiEndPoints.addQualityOfTestingRecord,
        {
          departmentId: projectData.departmentName.value,
          azureProjectId: projectData.azureProjectName.value,
          year: projectData.year.getFullYear(),
          monthCycle: projectData.monthCycle.value,
          bugDetectionPhase: projectData.bugDetectionPhase.value,
          rating: projectData.rating,
          numberOfBug: projectData.numberOfBug,
          comment: projectData.comment,
          username: employeeName,
        }
      );
      // clearFormFields();
      clearFormFieldsOnAdd();
      // document.getElementById("exampleModalLong2").click();
      props.fetchQualityTesting();
      toast.success("Form submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const errorMessage = error.response.data.message;
        alert(`${errorMessage}`);
      } else {
        console.error("Error submission", error);
      }
    }
  };

  return (
    <form
      className="modal fade bd-example-modal-lg"
      id="exampleModalLong2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Quality Of Testing
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearFormFields();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.departmentNameError ? "invalid" : ""}
              label="Department Name"
              id="deptName"
              placeholder="Department Name"
              value={projectData.departmentName}
              onChange={(value) => {
                handleProjectData(value, "departmentName");
              }}
              options={masterData.departments}
              isSearchable={true}
            />
            <SelectList
              ErrorStyle={showValidation.azureProjectNameError ? "invalid" : ""}
              label="Project Name"
              id="Project"
              placeholder="Project Name"
              value={projectData.azureProjectName}
              onChange={(value) => {
                handleProjectData(value, "azureProjectName");
              }}
              options={projectList}
              isSearchable={true}
            />
            <DatePickerField
              id="projYear"
              label=" Year"
              showYearPicker
              dateFormat="yyyy"
              placeholderText="Select Year"
              selected={projectData.year}
              onChange={(date) => {
                handleProjectData(date, "year");
              }}
            />
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.monthCycleError ? "invalid" : ""}
              label="Quarter"
              id="Quarter"
              placeholder="Quarter"
              options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
              defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
              value={projectData.monthCycle}
              onChange={(value) => handleProjectData(value, "monthCycle")}
            />
            <SelectList
              ErrorStyle={
                showValidation.bugDetectionPhaseError ? "invalid" : ""
              }
              label="Bug Detection Phase"
              id="bugDetectionPhase"
              placeholder="Bug Detection Phase"
              options={["Development", "Design", "UAT"]}
              defaultValue={{ value: "UAT", label: "UAT" }}
              value={projectData.bugDetectionPhase}
              onChange={(value) => {
                handleProjectData(value, "bugDetectionPhase");
              }}
            />
            <TextInputField
              ErrorStyle={showValidation.ratingError ? "invalid" : ""}
              label="Rating"
              id="rating"
              value={projectData.rating}
              onChange={(e) => {
                handleProjectData(e.target.value, "rating");
              }}
              loading={loading}
            />
          </div>
          <div className="modal-body">
            <TextInputField
              ErrorStyle={showValidation.numberOfBugError ? "invalid" : ""}
              label="No Of Bugs"
              id="numberOfBug"
              value={projectData.numberOfBug}
              onChange={(e) => {
                handleProjectData(e.target.value, "numberOfBug");
              }}
              loading={loading}
            />
            <TextArea
              ErrorStyle={showValidation.commentError ? "invalid" : ""}
              label="Comment"
              id="comment"
              rows={1}
              cols={20}
              value={projectData.comment}
              onChange={(e) => handleProjectData(e.target.value, "comment")}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-success btn-Add"
            >
              Add
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                clearFormFields();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormQualityTesting;
