import React from "react";
import DatePickerField from "./DatePickerField";
import SelectInputField from "./SelectInputField";
import TextArea from "./TextArea";

const ALMRow = ({
  activityName,
  startDate,
  endDate,
  status,
  comment,
  handleDataChange,
  id,
  statusValidation,
}) => {
  return (
    <tr>
      <td className="">{activityName}</td>
      <td>
        <DatePickerField
          design="design"
          type="date"
          value={startDate}
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          onChange={(date) => handleDataChange(id, { startDate: date })}
        />
      </td>
      <td>
        <DatePickerField
          design="design"
          selected={endDate}
          dateFormat="yyyy-MM-dd"
          onChange={(date) => handleDataChange(id, { endDate: date })}
        />
      </td>
      <td>
        <SelectInputField
          design={`design status-ALM ${
            statusValidation && !status ? "invalid" : ""
          }`}
          className="status-ALM"
          id="selectField"
          options={[
            "Full Compliance",
            "Partial Compliance",
            "Non Compliance",
            "N/A",
          ]}
          selected={status}
          value={status}
          onChange={(event) => {
            const selectedValue = event.target.value;
            handleDataChange(id, { status: selectedValue });
          }}
        />
      </td>
      <td>
        <TextArea
          design="design"
          rows={1}
          cols={20}
          value={comment}
          onChange={(e) => handleDataChange(id, { comment: e.target.value })}
        />
      </td>
    </tr>
  );
};

export default ALMRow;
