import React, { useEffect, useState } from "react";
import ALMRow from "../../Components/ALMRow";
import { Tabs } from "antd";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";

const { TabPane } = Tabs;

const FormApplicationLifeCycle = (props) => {
  const tabLabels = [
    "Analysis/Plan",
    "Design",
    "Database Design",
    "Development/Implementation",
    "Testing",
    "Project Closure",
    "Report",
    "Review",
    "Sign Off",
  ];

  const initialTabData = [
    [
      {
        activityId: 1,
        activityName: "Proposal and Estimation (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 2,
        activityName: "Kick Off Meeting (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 3,
        activityName: "SOW (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 4,
        activityName: "BRD/SRS/User Story (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 5,
        activityName: "Project Plan/Sprint Plan/Backlog (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 6,
        activityName: "System Architecture Updated (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 7,
        activityName: "HLD (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 8,
        activityName: "LLD (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 9,
        activityName: "Wireframe (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 10,
        activityName: "HTML (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 11,
        activityName: "Create Database (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 12,
        activityName: "Database Document (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 13,
        activityName: "Create ER Diagram (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 14,
        activityName: "Coding/Module Development (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 15,
        activityName: "Unit Testing (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 16,
        activityName: "Code Review (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 17,
        activityName: "Functional Testing (Bug Report + RCA) (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 18,
        activityName: "Functional Test Cases (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 19,
        activityName: "System Testing (Bug Report + RCA) (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 20,
        activityName: "UAT (Bug Report + RCA) (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 21,
        activityName: "Project Closure Meeting (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 22,
        activityName: "Deployment Document (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 23,
        activityName: "Update Artifacts (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 24,
        activityName: "User Manual (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 25,
        activityName: "Bug Report + RCA (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
      {
        activityId: 26,
        activityName: "Project Status Report (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 27,
        activityName: "Milestone/Sprint Plan/Spirit Closure/Retrospective (M)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
    [
      {
        activityId: 28,
        activityName: "Sign Off (O)",
        startDate: "",
        endDate: "",
        status: "",
        comment: "",
      },
    ],
  ];
  const [tabsKey, setTabsKey] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [tabData, setTabData] = useState(initialTabData);
  const topData = {
    departmentName: "",
    azureProjectName: "",
    // quarter: "",
    // year: new Date(),
    quarter: { value: "Oct-Dec", label: "Oct-Dec" },
    year: new Date(2023, 0, 1),
  };
  const [projectData, setProjectData] = useState(topData);
  const [isLastPhase, setIsLastPhase] = useState(false);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
        ]);

        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;

          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });

          setMasterData({
            ...{ departments },
          });
        }
      } catch (error) {}
    };
    getDepartments();
  }, []);

  useEffect(() => {
    const getProjects = async () => {
      let projectList = [];
      if (projectData.departmentName) {
        const departmentName = projectData.departmentName.label;
        try {
          const resp = await callAPI(
            "GET",
            apiEndPoints.getProjName + "?departmentName=" + departmentName
          );
          projectList = resp.data.data;
          projectList.forEach((item) => {
            item.value = item.azureProjectId;
            item.label = item.azureProjectName;
            delete item.azureProjectId;
            delete item.azureProjectName;
            delete item.department;
          });
        } catch (err) {}
      }
      setProjectList(projectList);
    };
    getProjects();
  }, [projectData.departmentName]);

  const isStatusEmpty = () => {
    const currentIndex = parseInt(activeTab) - 1;
    const isEmpty = tabData[currentIndex].some(
      (rowData) => rowData.status === ""
    );

    return isEmpty;
  };

  const isOverallStatusEmpty = () => {
    const isEmpty = tabData.some((phase) =>
      phase.some((rowData) => rowData.status === "")
    );

    return isEmpty;
  };

  const [masterData, setMasterData] = useState({
    departments: [],
  });
  const [projectList, setProjectList] = useState([]);

  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
    setIsLastPhase(activeKey === String(tabLabels.length));
  };

  const getTabData = (tabKey) => {
    return tabData[parseInt(tabKey) - 1];
  };

  const handleTabDataChange = (tabKey, id, data) => {
    const tabDataCopy = [...tabData];
    const tabIndex = parseInt(tabKey) - 1;
    const row = tabDataCopy[tabIndex].find((row) => row.activityName === id);

    if (row) {
      Object.assign(row, data);

      if (data.status === "") {
        toast.error("Status field cannot be blank", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

      setTabData(tabDataCopy);
    }
  };

  const clearFormFields = () => {
    setTabData(initialTabData);
    setActiveTab("1");
    setProjectData(topData);
    setTabsKey((prevKey) => prevKey + 1);
  };

  const handleSubmitButtonClick = async () => {
    try {
      if (
        !projectData.departmentName ||
        !projectData.azureProjectName ||
        !projectData.year ||
        !projectData.quarter
      ) {
        alert(
          "Please fill in all the required fields (Department, Project, Year, Quarter)."
        );
        return;
      }

      if (isOverallStatusEmpty()) {
        toast.error("Please fill Status fields in all Phases.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      const employeeName = sessionStorage.getItem("employeeName");
      const allPhasesData = {
        departmentId: projectData.departmentName.value,
        azureProjectId: projectData.azureProjectName.value,
        quarter: projectData.quarter.value,
        year: projectData.year.getFullYear(),
        datalist: tabData.map((phaseData, phaseIndex) => ({
          phaseId: phaseIndex + 1,
          activities: phaseData.map((activity) => ({
            activityId: activity.activityId,
            startDate: activity.startDate,
            endDate: activity.endDate,
            status: activity.status,
            comment: activity.comment,
            username: employeeName,
          })),
        })),
      };

      const response = await axios.post(
        apiEndPoints.addALMRecord,
        allPhasesData
      );
      clearFormFields();

      document.getElementById("exampleModalLong2").click();
      props.fetchApplicationLifeCycle();

      const d = JSON.stringify(allPhasesData);

      toast.success("Form Submitted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {}
  };

  const handleProjectData = (val, key) => {
    setProjectData((previousData) => {
      return {
        ...previousData,
        [key]: val,
      };
    });
  };

  return (
    <div
      className="modal fade modal-fade-ALM"
      id="exampleModalLong2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg-ALM" role="document">
        <div className="modal-content modal-content-ALM">
          <div className="modal-header">
            <h5 className="modal-title">Application Life Cycle</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearFormFields();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body-ALM">
            <div className="modal-body">
              <SelectList
                design="ALM-top-design"
                label="Department Name"
                id="deptName"
                placeholder="Department Name"
                value={projectData.departmentName}
                onChange={(value) => {
                  handleProjectData(value, "departmentName");
                }}
                options={masterData.departments}
                isSearchable={true}
              />
              <SelectList
                design="ALM-top-design"
                label="Project Name"
                id="Project"
                placeholder="Project Name"
                value={projectData.azureProjectName}
                onChange={(value) => {
                  handleProjectData(value, "azureProjectName");
                }}
                options={projectList}
                isSearchable={true}
              />
              <DatePickerField
                design="ALM-top-design"
                id="projYear"
                label=" Year"
                showYearPicker
                dateFormat="yyyy"
                value={projectData.year}
                placeholderText="Select Year"
                selected={projectData.year}
                onChange={(date) => {
                  handleProjectData(date, "year");
                }}
              />
              <SelectList
                design="ALM-top-design"
                label="Quarter"
                id="Quarter"
                placeholder="Quarter"
                options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
                defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
                value={projectData.quarter}
                onChange={(value) => handleProjectData(value, "quarter")}
              />
            </div>
            <div className="tab__section">
              <Tabs
                key={tabsKey}
                defaultActiveKey={activeTab}
                onChange={handleTabChange}
              >
                {tabLabels.map((tabLabel, index) => (
                  <TabPane tab={tabLabel} key={String(index + 1)}>
                    <div className="application__life">
                      <table className="table table-responsive">
                        <thead>
                          <tr>
                            <th>Activities</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getTabData(index + 1).map((rowData, rowIndex) => (
                            <ALMRow
                              key={rowData.activityId}
                              index={rowIndex + 1}
                              activityName={rowData.activityName}
                              startDate={rowData.startDate}
                              endDate={rowData.endDate}
                              status={rowData.status}
                              comment={rowData.comment}
                              handleDataChange={(id, data) =>
                                handleTabDataChange(activeTab, id, data)
                              }
                              id={rowData.activityName}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                clearFormFields();
              }}
            >
              Close
            </button>

            {isLastPhase && (
              <button
                type="button"
                className="btn btn-success btn-Add"
                onClick={handleSubmitButtonClick}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormApplicationLifeCycle;
