import React from "react";
import { Button, Modal } from "antd";
import "../DeleteCmfModal/DeleteCmfmodal.css";

function DeleteCmfModal(props) {
  const { title, onClick, visible, onCancel } = props;

  return (
    <Modal
      title={title ?? "Please Confirm"}
      className="deleteCmfModal"
      width={400}
      centered
      open={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        <Button onClick={onCancel}>{"No"}</Button>,
        <Button
          type="primary"
          onClick={() => {
            onClick();
            onCancel();
          }}
        >
          {"Yes"}
        </Button>,
      ]}
    >
      <div className="form__fields row border-0 p-0">
        <div className="col-lg-12 text-center">
          <p>{`Are you sure you want to delete this item?`}</p>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteCmfModal;
