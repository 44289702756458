import React from "react";
import DatePickerField from "./DatePickerField";
import SelectInputField from "./SelectInputField";
import TextInputField from "./TextInputField";

const ProjectProcessRow = ({
  documentType,
  documentName,
  dateOfSubmission,
  availableOn,
  status,
  updatedDate,
  pathFolderLocation,
  documentCompliance,
  projectComment,
  handleDataChange,
  configurationCompliance,
  id,
  tabData,
  statusValidation,
}) => {
  return (
    <tr>
      <td>{documentType}</td>
      <td>
        <TextInputField
          type="text"
          design="design"
          value={documentName}
          onChange={(e) =>
            handleDataChange(id, { documentName: e.target.value })
          }
        />
      </td>
      <td>
        <DatePickerField
          design="design"
          selected={dateOfSubmission}
          dateFormat="yyyy-MM-dd"
          onChange={(date) => handleDataChange(id, { dateOfSubmission: date })}
        />
      </td>
      <td>
        <SelectInputField
          design="design status-ProjectProcess"
          className="status-ProjectProcess"
          id="availableOn"
          value={availableOn}
          options={["Jira", "TFS", "SVN", "DevOps"]}
          selected={availableOn || "DevOps"}
          defaultValue="DevOps"
          onChange={(event, value) => {
            const selectedValue = event.target.value;
            handleDataChange(id, { availableOn: selectedValue }, value);
          }}
        />
      </td>
      <td>
        <SelectInputField
          design="design status-ProjectProcess"
          className="status-ProjectProcess"
          id="Status"
          options={["Completed", "Pending", "Approved", "N/A"]}
          selected={status}
          value={status}
          onChange={(event) => {
            const selectedValue = event.target.value;
            handleDataChange(id, { status: selectedValue });
          }}
        />
      </td>
      <td>
        <DatePickerField
          design="design"
          selected={updatedDate}
          dateFormat="yyyy-MM-dd"
          onChange={(date) => handleDataChange(id, { updatedDate: date })}
        />
      </td>
      <td>
        <TextInputField
          design="design"
          rows={1}
          cols={20}
          value={pathFolderLocation}
          onChange={(e) =>
            handleDataChange(id, { pathFolderLocation: e.target.value })
          }
        />
      </td>
      <td>
        <SelectInputField
          design="design status-ProjectProcess"
          className="status-ProjectProcess"
          id="documenetCompliance"
          options={["Yes", "No", "N/A"]}
          selected={documentCompliance}
          value={documentCompliance}
          onChange={(event) => {
            const selectedValue = event.target.value;
            handleDataChange(id, { documentCompliance: selectedValue });
          }}
        />
      </td>
      <td>
        <SelectInputField
          design="design status-ProjectProcess"
          className="status-ProjectProcess"
          value={configurationCompliance}
          id="configurationCompliance"
          options={["Yes", "No", "N/A"]}
          selected={configurationCompliance}
          onChange={(event) => {
            const selectedValue = event.target.value;
            handleDataChange(id, { configurationCompliance: selectedValue });
          }}
        />
      </td>
    </tr>
  );
};

export default ProjectProcessRow;
