import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import TopNavigation from "./Navigation/TopNavigation";
import SideBar from "./SideBar/SideBar";
import Dashboard from "./Pages/Dashboard/Dashboard";
import CustomerSatisfaction from "./Pages/customerSatisfaction/customerSatisfaction";
import CodeReview from "./Pages/CodeReview/CodeReview";
import ProjectProcess from "./Pages/ProjectProcess/ProjectProcess";
import ApplicationLifeCycle from "./Pages/ApplicationLifeCycle/ApplicationLifeCycle";
import ProjectEffort from "./Pages/ProjectEffort/ProjectEffort";
import ProjectPlan from "./Pages/ProjectPlan/ProjectPlan";
import TrackingChange from "./Pages/TrackingChange/TrackingChange";
import AutomationSecurity from "./Pages/AutomationSecurity/AutomationSecurity";
import QualityDevelopment from "./Pages/QualityDevelopment/QualityDevelopment";
import QualityTesting from "./Pages/QualityTesting/QualityTesting";
import ArchitectureApproval from "./Pages/ArchitectureApproval/ArchitectureApproval";
import Login from "./Pages/Login/Login";
import { useState } from "react";

function App() {
  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem("loggedIn") === "true"
  );

  const handleLogout = () => {
    setLoggedIn(false);
    sessionStorage.setItem("loggedIn", "false");
  };

  return (
    <Router>
      {loggedIn ? (
        <div>
          <div className="header">
            <TopNavigation loggedIn={loggedIn} onLogout={handleLogout} />
          </div>

          <div className="main-container">
            <div className="sidebar-main">
              <SideBar />
            </div>
            <div className="App-container">
              <Routes>
                <Route
                  path="/Login"
                  element={<Login setLoggedIn={setLoggedIn} />}
                />
                <>
                  <Route path="/Dashboard" element={<Dashboard />} />
                  <Route
                    path="/CustomerSatisfaction"
                    element={<CustomerSatisfaction />}
                  />
                  <Route path="/CodeReview" element={<CodeReview />} />
                  <Route path="/ProjectProcess" element={<ProjectProcess />} />
                  <Route
                    path="/ApplicationLifeCycle"
                    element={<ApplicationLifeCycle />}
                  />
                  <Route path="/ProjectEffort" element={<ProjectEffort />} />
                  <Route path="/ProjectPlan" element={<ProjectPlan />} />
                  <Route path="/TrackingChange" element={<TrackingChange />} />
                  <Route
                    path="/AutomationSecurity"
                    element={<AutomationSecurity />}
                  />
                  <Route
                    path="/QualityDevelopment"
                    element={<QualityDevelopment />}
                  />
                  <Route path="/QualityTesting" element={<QualityTesting />} />
                  <Route
                    path="/ArchitectureApproval"
                    element={<ArchitectureApproval />}
                  />
                </>
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <Login setLoggedIn={setLoggedIn} />
      )}
    </Router>
  );
}

export default App;
