import React, { useEffect, useState } from "react";
import ProjectProcessRow from "../../Components/ProjectProcessRow";
import { Tabs } from "antd";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import TextInputField from "../../Components/TextInputField";

const { TabPane } = Tabs;

const initialTabData = [
  {
    documentTypeId: 1,
    documentName: "Estimation Sheet",
    documentType: "Estimation Sheet",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 2,
    documentName: "SOW",
    documentType: "SOW",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 3,
    documentName: "BRD/SRS",
    documentType: "BRD/SRS",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 4,
    documentName: "Architecture Document",
    documentType: "Architecture Document",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 5,
    documentName: "Project Plan",
    documentType: "Project Plan",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 6,
    documentName: "Wireframe/PSD",
    documentType: "Wireframe/PSD",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 7,
    documentName: "CR Sheet",
    documentType: "CR Sheet",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 8,
    documentName: "Project Status Report",
    documentType: "Project Status Report",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 9,
    documentName: "HLD/LLD",
    documentType: "HLD/LLD",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 10,
    documentName: "Release Document",
    documentType: "Release Document",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 11,
    documentName: "Database Document",
    documentType: "Database Document",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 12,
    documentName: "Deployment Document",
    documentType: "Deployment Document",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
  {
    documentTypeId: 13,
    documentName: "Bug Report",
    documentType: "Bug Report",
    dateOfSubmission: "",
    availableOn: "DevOps",
    status: "",
    updatedDate: "",
    pathFolderLocation: "DevOps",
    documentCompliance: "",
    configurationCompliance: "",
  },
];

const NewFormProjectProcess = (props) => {
  const [showValidation, setShowValidation] = useState(false);
  const topData = {
    departmentName: "",
    azureProjectName: "",
    // quarter: "",
    // year: new Date(),
    quarter: { value: "Oct-Dec", label: "Oct-Dec" },
    year: new Date(2023, 0, 1),
    projectPhase: "",
    teamLead: "",
  };
  const [projectData, setProjectData] = useState(topData);
  const [masterData, setMasterData] = useState({
    departments: [],
    projectPhases: [],
    documentType: [],
  });
  const [projectList, setProjectList] = useState([]);
  const [tabData, setTabData] = useState(initialTabData);

  const getTabData = (tabKey) => {
    return tabData[parseInt(tabKey) - 1];
  };

  const handleTabDataChange = (id, data) => {
    const updatedTabData = tabData.map((row) =>
      row.documentTypeId === id ? { ...row, ...data } : row
    );
    setTabData(updatedTabData);
  };

  const clearFormFields = () => {
    setTabData(initialTabData);
    setProjectData(topData);
  };

  const handleProjectData = (val, key) => {
    setProjectData((previousData) => {
      return {
        ...previousData,
        [key]: val,
      };
    });
  };

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
        ]);
        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;
          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });
          setMasterData({
            ...{ departments },
          });
        }
      } catch (error) {}
    };
    getDepartments();
  }, []);

  useEffect(() => {
    const getProjects = async () => {
      let projectList = [];
      if (projectData.departmentName) {
        const departmentName = projectData.departmentName.label;
        try {
          const resp = await callAPI(
            "GET",
            apiEndPoints.getProjName + "?departmentName=" + departmentName
          );
          projectList = resp.data.data;
          projectList.forEach((item) => {
            item.value = item.azureProjectId;
            item.label = item.azureProjectName;
            delete item.azureProjectId;
            delete item.azureProjectName;
            delete item.department;
          });
        } catch (err) {}
      }
      setProjectList(projectList);
    };
    getProjects();
  }, [projectData.departmentName]);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
          callAPI("GET", apiEndPoints.getProjectPhases),
          callAPI("GET", apiEndPoints.getDocType),
        ]);

        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;
          const projectPhases = allData[1].data.data;
          const documentType = allData[2].data.data;

          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });
          projectPhases.forEach((item) => {
            item.value = item.phaseId;
            item.label = item.phaseName;
            delete item.phaseId;
            delete item.phaseName;
          });
          documentType.forEach((item) => {
            item.value = item.id;
            item.label = item.documenttype;
            delete item.id;
            delete item.documenttype;
          });
          setMasterData({
            ...{ departments, projectPhases, documentType },
          });
        }
      } catch (error) {}
    };
    getDepartments();
    props.fetchProjectProcess();
  }, []);

  const handleSubmitButtonClick = async () => {
    try {
      // Validate required fields
      if (
        !projectData.departmentName ||
        !projectData.azureProjectName ||
        !projectData.year ||
        !projectData.quarter ||
        !projectData.projectPhase
      ) {
        toast.error(
          "Please fill in all the required fields (Department, Project, Year, Quarter and Project Phase).",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        return;
      }

      const invalidRows = tabData.filter(
        (rowData) =>
          !rowData.status ||
          !rowData.documentCompliance ||
          !rowData.configurationCompliance
      );
      if (invalidRows.length > 0) {
        toast.error(
          "Status, Document Compliance and Configuration Compliance are required!",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
        return;
      }

      const employeeName = sessionStorage.getItem("employeeName");
      const formData = {
        departmentId: projectData.departmentName.value,
        azureProjectId: projectData.azureProjectName.value,
        year: projectData.year.getFullYear(),
        quarter: projectData.quarter.value,
        projectPhaseId: projectData.projectPhase.value,
        teamLead: projectData.teamLead,
        datalist: tabData.map((rowData) => ({
          documentTypeId: rowData.documentTypeId,
          documentName: rowData.documentName,
          dateOfSubmission: rowData.dateOfSubmission,
          availableOn: rowData.availableOn,
          status: rowData.status,
          updatedDate: rowData.updatedDate,
          pathFolderLocation: rowData.pathFolderLocation,
          documentCompliance: rowData.documentCompliance,
          configurationCompliance: rowData.configurationCompliance,
          username: employeeName,
        })),
      };
      const a = JSON.stringify(formData);

      const response = await axios.post(apiEndPoints.addPPCRecord, formData);
      clearFormFields();
      document.getElementById("exampleModalLong2").click();

      if (response.data.success) {
        toast.success("Form Submitted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        props.fetchProjectProcess();
      } else {
        toast.error("Failed to submit form. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(
        "An error occurred while submitting the form. Please try again later.",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        }
      );
    }
  };

  return (
    <div
      className="modal fade modal-fade-ALM"
      id="exampleModalLong2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg-projectProcess" role="document">
        <div className="modal-content modal-content-projectProcess">
          <div className="modal-header">
            <h5 className="modal-title">Project Process Compliance</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body-ALM">
            <div className="modal-body">
              <SelectList
                // design="ALM-top-design"
                label="Department Name"
                id="deptName"
                placeholder="Department Name"
                value={projectData.departmentName}
                onChange={(value) => {
                  handleProjectData(value, "departmentName");
                }}
                options={masterData.departments}
                isSearchable={true}
              />
              <SelectList
                // design="ALM-top-design"
                label="Project Name"
                id="Project"
                placeholder="Project Name"
                value={projectData.azureProjectName}
                onChange={(value) => {
                  handleProjectData(value, "azureProjectName");
                }}
                options={projectList}
                isSearchable={true}
              />
              <DatePickerField
                // design="ALM-top-design"
                id="projYear"
                label=" Year"
                showYearPicker
                dateFormat="yyyy"
                placeholderText="Select Year"
                selected={projectData.year}
                onChange={(date) => {
                  handleProjectData(date, "year");
                }}
              />
            </div>
            <div className="modal-body">
              <SelectList
                // design="ALM-top-design"
                label="Quarter"
                id="Quarter"
                placeholder="Quarter"
                options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
                defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
                value={projectData.quarter}
                onChange={(value) => handleProjectData(value, "quarter")}
              />
              <SelectList
                label="Project Phase"
                id="projPhase"
                placeholder="Project Phase"
                value={projectData.projectPhase}
                onChange={(value, e) =>
                  handleProjectData(value, "projectPhase")
                }
                options={masterData.projectPhases}
                isSearchable={true}
              />
              <TextInputField
                ErrorStyle={showValidation.teamLeadError ? "invalid" : ""}
                label="Team Lead"
                id="TeamLead"
                placeholder="Enter Team Lead"
                value={projectData.teamLead}
                onChange={(e) => handleProjectData(e.target.value, "teamLead")}
              />
            </div>
            <div className="modal-body">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th>Document Type</th>
                    <th>Document Name</th>
                    <th>Submission Date</th>
                    <th>Available On </th>
                    <th>Status</th>
                    <th>Update Date</th>
                    <th>Path Folder Location</th>
                    <th>Document Compliance</th>
                    <th>Configuration Compliance</th>
                  </tr>
                </thead>
                <tbody>
                  {tabData.map((rowData, rowIndex) => (
                    <ProjectProcessRow
                      key={rowData.documentTypeId}
                      index={rowIndex + 1}
                      documentType={rowData.documentType}
                      documentName={rowData.documentName}
                      dateOfSubmission={rowData.dateOfSubmission}
                      availableOn={rowData.availableOn}
                      status={rowData.status}
                      updatedDate={rowData.updatedDate}
                      pathFolderLocation={rowData.pathFolderLocation}
                      documentCompliance={rowData.documentCompliance}
                      configurationCompliance={rowData.configurationCompliance}
                      handleDataChange={(id, data) =>
                        handleTabDataChange(id, data)
                      }
                      id={rowData.documentTypeId}
                      statusValidation={showValidation}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmitButtonClick}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                clearFormFields();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFormProjectProcess;
