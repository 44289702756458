import React, { useEffect, useState } from "react";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import TextArea from "../../Components/TextArea";
import "../Pages.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialProjectData = {
	departmentName: "",
	azureProjectName: "",
	// year: new Date(),
	// monthCycle: "",
	monthCycle: { value: "Oct-Dec", label: "Oct-Dec" },
	year: new Date(2023, 0, 1),
	effortVarianceCompliance: { value: "Yes", label: "Yes" },
	status: { value: "Average", label: "Average" },
	comments: "No valid details found",
};
const FormProjectEffort = (props) => {
	const [projectData, setProjectData] = useState(initialProjectData);
	const [showValidation, setShowValidation] = useState({
		departmentNameError: null,
		azureProjectNameError: null,
		// monthCycleError: null,
		// effortVarianceComplianceError: null,
		// statusError: null,
		// commentsError: null,
	});
	const [masterData, setMasterData] = useState({
		departments: [],
	});
	const [projectList, setProjectList] = useState([]);

	useEffect(() => {
		const getDepartments = async () => {
			try {
				const allData = await axios.all([
					callAPI("GET", apiEndPoints.getDeptName),
				]);

				if (allData && allData.length > 0) {
					const departments = allData[0].data.data;

					departments.forEach((item) => {
						item.value = item.departmentId;
						item.label = item.departmentName;
						delete item.departmentId;
						delete item.departmentName;
					});

					setMasterData({
						...{ departments },
					});
				}
			} catch (error) {}
		};
		props.fetchProjectEffort();
		getDepartments();
	}, []);

	const handleProjectData = (val, key) => {
		setProjectData((previousData) => {
			return {
				...previousData,
				[key]: val,
			};
		});
		setShowValidation((previousData) => {
			return {
				...previousData,
				[`${key}Error`]: val ? false : true,
			};
		});
	};

	useEffect(() => {
		const getProjects = async () => {
			let projectList = [];
			if (projectData.departmentName) {
				const departmentName = projectData.departmentName.label;
				try {
					const resp = await callAPI(
						"GET",
						apiEndPoints.getProjName + "?departmentName=" + departmentName
					);
					projectList = resp.data.data;
					projectList.forEach((item) => {
						item.value = item.azureProjectId;
						item.label = item.azureProjectName;
						delete item.azureProjectId;
						delete item.azureProjectName;
						delete item.department;
					});
				} catch (err) {
					console.log("Error in fetching Project Names: ", err);
				}
			}
			setProjectList(projectList);
		};
		getProjects();
	}, [projectData.departmentName]);

	const clearFormFields = () => {
		setProjectData(initialProjectData);
		setShowValidation(false);
	};
	const clearFormFieldsOnAdd = () => {
		setProjectData({
			departmentName: projectData.departmentName,
			azureProjectName: "",
			monthCycle: projectData.monthCycle,
			year: new Date(2023, 0, 1),
			effortVarianceCompliance: projectData.effortVarianceCompliance,
			status: projectData.status,
			comments: projectData.comments,
		});

		setShowValidation((prev) => {
			return {
				...prev,
				azureProjectNameError: null,
				// effortVarianceComplianceError: null,
				// statusError: null,
				// commentsError: null,
			};
		});
	};

	const validationHandler = () => {
		const data = Object.values(showValidation).every((item) => item === false);

		const data1 = { ...projectData };
		const data2 = { ...showValidation };

		for (const key in data1) {
			if (data1[key] === "") {
				data2[`${key}Error`] = true;
			}
		}
		setShowValidation(data2);
		console.log(data2, "test");

		return data;
	};

	const handleSubmit = async () => {
		if (validationHandler() === false) {
			return false;
		}
		try {
			const employeeName = sessionStorage.getItem("employeeName");
			const response = await axios.post(
				apiEndPoints.addProjectEffortVarianceRecord,
				{
					departmentId: projectData.departmentName.value,
					azureProjectId: projectData.azureProjectName.value,
					year: projectData.year.getFullYear(),
					monthCycle: projectData.monthCycle.value,
					effortVarianceCompliance: projectData.effortVarianceCompliance.value,
					status: projectData.status.value,
					comments: projectData.comments,
					username: employeeName,
				}
			);
			// clearFormFields();
			console.log(response, "-----------s");
			clearFormFieldsOnAdd();
			// document.getElementById("exampleModalLong2").click();
			props.fetchProjectEffort();
			toast.success("Form submitted successfully", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
			});
		} catch (error) {
			if (error.response && error.response.status === 409) {
				const errorMessage = error.response.data.message;
				alert(`${errorMessage}`);
			} else {
				console.error("Error submission", error);
			}
		}
	};

	return (
		<form
			className="modal fade bd-example-modal-lg"
			id="exampleModalLong2"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="myLargeModalLabel"
			aria-hidden="true">
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLongTitle">
							Project Effort Variance
						</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => {
								clearFormFields();
							}}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<SelectList
							ErrorStyle={showValidation.departmentNameError ? "invalid" : ""}
							label="Department Name"
							id="deptName"
							placeholder="Department Name"
							value={projectData.departmentName}
							onChange={(value) => {
								handleProjectData(value, "departmentName");
							}}
							options={masterData.departments}
							isSearchable={true}
						/>
						<SelectList
							ErrorStyle={showValidation.azureProjectNameError ? "invalid" : ""}
							label="Project Name"
							id="Project"
							placeholder="Project Name"
							value={projectData.azureProjectName}
							onChange={(value) => {
								handleProjectData(value, "azureProjectName");
							}}
							options={projectList}
							isSearchable={true}
						/>
						<DatePickerField
							id="projYear"
							label=" Year"
							showYearPicker
							dateFormat="yyyy"
							placeholderText="Select Year"
							selected={projectData.year}
							onChange={(date) => {
								handleProjectData(date, "year");
							}}
						/>
					</div>
					<div className="modal-body">
						<SelectList
							ErrorStyle={showValidation.monthCycleError ? "invalid" : ""}
							label="Quarter"
							id="Quarter"
							placeholder="Quarter"
							options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
							defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
							value={projectData.monthCycle}
							onChange={(value) => handleProjectData(value, "monthCycle")}
						/>
						<SelectList
							ErrorStyle={
								showValidation.effortVarianceComplianceError ? "invalid" : ""
							}
							label="Effort Variance Compliance"
							id="effortVarianceCompliance"
							placeholder="Effort Variance"
							options={["Yes", "No"]}
							defaultValue={{ value: "Yes", label: "Yes" }}
							value={projectData.effortVarianceCompliance}
							onChange={(value) =>
								handleProjectData(value, "effortVarianceCompliance")
							}
						/>
						<SelectList
							ErrorStyle={showValidation.statusError ? "invalid" : ""}
							label="Status"
							id="Status"
							placeholder="Status"
							options={["Good", "Average", "Need Improvement"]}
							defaultValue={{ value: "Average", label: "Average" }}
							value={projectData.status}
							onChange={(value) => handleProjectData(value, "status")}
						/>
					</div>
					<div className="modal-body">
						<TextArea
							ErrorStyle={showValidation.commentsError ? "invalid" : ""}
							label="Comment"
							id="comment"
							rows={1}
							cols={20}
							value={projectData.comments}
							onChange={(e) => handleProjectData(e.target.value, "comments")}
						/>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={handleSubmit}
							className="btn btn-success btn-Add">
							Add
						</button>
						<button
							type="button"
							className="btn btn-primary"
							data-dismiss="modal"
							onClick={() => {
								clearFormFields();
							}}>
							Close
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default FormProjectEffort;
