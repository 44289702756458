import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { apiEndPoints } from "../../constants/apiEndPoints";
import DatePickerField from "../../Components/DatePickerField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateQualityTesting = ({ record, onUpdate, onUpdateFunction }) => {
	const [editedRecord, setEditedRecord] = useState({
		...record,
		year: new Date(record.year.toString()),
	});

	const [showValidation, setShowValidation] = useState({
		monthCycleError: "",
		bugDetectionPhaseError: "",
		ratingError: "",
		numberOfBugError: "",
		commentError: "",
	});

	const handleFieldChange = (fieldName, value) => {
		const updatedRecord = { ...editedRecord };
		if (fieldName === "dateformat") {
			updatedRecord.date = formatDateToYYYYMMDD(value);
		} else {
			updatedRecord[fieldName] = value;
		}

		const newValidationState = { ...showValidation };

		if (fieldName === "monthCycle") {
			newValidationState.monthCycleError = value
				? ""
				: "This field is required.";
		} else if (fieldName === "bugDetectionPhase") {
			newValidationState.bugDetectionPhaseError = value
				? ""
				: "This field is required.";
		} else if (fieldName === "rating") {
			newValidationState.ratingError = value ? "" : "This field is required.";
		} else if (fieldName === "numberOfBug") {
			newValidationState.numberOfBugError = value
				? ""
				: "This field is required.";
		} else if (fieldName === "comment") {
			newValidationState.commentError = value ? "" : "This field is required.";
		}
		setShowValidation(newValidationState);
		setEditedRecord(updatedRecord);
	};

	const formatDateToYYYYMMDD = (inputDate) => {
		const date = new Date(inputDate);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const handleUpdate = async () => {
		const isFormValid = Object.values(showValidation).every(
			(error) => error === ""
		);
		if (!isFormValid) {
			return;
		}
		try {
			const employeeName = sessionStorage.getItem("employeeName");
			const response = await axios.put(
				apiEndPoints.updateQualityOfTestingRecord,
				{
					...editedRecord,
					year: editedRecord?.year.getFullYear(),
					username: employeeName,
				}
			);
			if (response.status === 200) {
				toast.success("Updated successfully", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 2000, 
				});
				onUpdateFunction();
				onUpdate();
			}
		} catch (error) {}
	};

	return (
		<Modal
			className="modal fade bd-example-modal-lg"
			show={true}
			onHide={onUpdate}
			centered
			size="lg">
			<Modal.Header closeButton className="modal-header">
				<Modal.Title>Update Quality Of Testing</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-body">
				<Form style={{ width: "100%" }}>
					<div className="row">
						<Form.Group
							className="form-group col-md-4"
							controlId="departmentName">
							<Form.Label>Department Name:</Form.Label>
							<Form.Control
								disabled
								type="text"
								value={editedRecord.departmentName}
								onChange={(e) =>
									handleFieldChange("departmentName", e.target.value)
								}
							/>
						</Form.Group>
						<Form.Group
							className="form-group col-md-4"
							controlId="azureProjectName">
							<Form.Label>Project Name:</Form.Label>
							<Form.Control
								disabled
								type="text"
								value={editedRecord.azureProjectName}
								onChange={(e) =>
									handleFieldChange("azureProjectName", e.target.value)
								}
							/>
						</Form.Group>
						<DatePickerField
							disabled
							className="form-control"
							label="Year:"
							type="year"
							showYearPicker
							selected={editedRecord?.year ?? new Date()}
							dateFormat="yyyy"
							onChange={(year) => handleFieldChange("year", year)}
						/>
					</div>
					<div className="row">
						<Form.Group className="form-group col-md-4" controlId="monthCycle">
							<Form.Label>Quarter:</Form.Label>
							<Form.Control
								// disabled
								as="select"
								value={editedRecord.monthCycle}
								className={showValidation.monthCycleError ? "invalid" : ""}
								onChange={(e) =>
									handleFieldChange("monthCycle", e.target.value)
								}>
								<option value="Jan-Mar">Jan-Mar</option>
								<option value="Apr-Jun">Apr-Jun</option>
								<option value="July-Sep">July-Sep</option>
								<option value="Oct-Dec">Oct-Dec</option>
							</Form.Control>
						</Form.Group>
						<Form.Group
							className="form-group col-md-4"
							controlId="bugDetectionPhase">
							<Form.Label>Bug Detection Phase:</Form.Label>
							<Form.Control
								as="select"
								value={editedRecord.bugDetectionPhase}
								className={
									showValidation.bugDetectionPhaseError ? "invalid" : ""
								}
								onChange={(e) =>
									handleFieldChange("bugDetectionPhase", e.target.value)
								}>
								<option value="Development">Development</option>
								<option value="Design">Design</option>
								<option value="UAT">UAT</option>
							</Form.Control>
						</Form.Group>
						<Form.Group className="form-group col-md-4" controlId="rating">
							<Form.Label>Rating:</Form.Label>
							<Form.Control
								type="text"
								value={editedRecord.rating}
								className={showValidation.ratingError ? "invalid" : ""}
								onChange={(e) => handleFieldChange("rating", e.target.value)}
							/>
						</Form.Group>
					</div>
					<div className="row">
						<Form.Group className="form-group col-md-4" controlId="numberOfBug">
							<Form.Label>Number Of Bugs:</Form.Label>
							<Form.Control
								type="text"
								value={editedRecord.numberOfBug}
								className={showValidation.numberOfBugError ? "invalid" : ""}
								onChange={(e) =>
									handleFieldChange("numberOfBug", e.target.value)
								}
							/>
						</Form.Group>
						<Form.Group className="form-group col-md-4" controlId="comment">
							<Form.Label>Comment:</Form.Label>
							<Form.Control
								as="textarea"
								rows={1}
								value={editedRecord.comment}
								className={showValidation.commentError ? "invalid" : ""}
								onChange={(e) => handleFieldChange("comment", e.target.value)}
							/>
						</Form.Group>
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={handleUpdate}>
					Update
				</Button>
				<Button variant="secondary" onClick={onUpdate}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default UpdateQualityTesting;
