import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../Pages.css";
import { Table, Spin } from "antd";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import UpdateCodeReview from "../CodeReview/UpdateCodeReview";
import "react-toastify/dist/ReactToastify.css";
import DeleteCmfModal from "../../Components/DeleteCmfModal/DeleteCmfModal";

const CodeReviewTable = (props) => {
	const [editingRecord, setEditingRecord] = useState(null);
	const [deleteModalData, setDeleteModalData] = useState({
		show: false,
		id: "",
	});
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
	});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [expandedComments, setExpandedComments] = useState({});

	useEffect(() => {
		setLoading(true);
		props.fetchCodeReview(pagination.current, pagination.pageSize);
		setLoading(false);
	}, [props.searchParams]);

	const handleEdit = (record) => {
		setEditingRecord(record);
		setIsModalVisible(true);
	};

	const handleTableChange = (pagination) => {
		setPagination(pagination);
	};

	const onEdit = (record) => {
		setEditingRecord(record);
	};

	const onDelete = async (id) => {
		props.onDeleteFunction(id);
	};

	const handleShowMore = (record) => {
		setExpandedComments((prevExpanded) => ({
			...prevExpanded,
			[record.id]: !prevExpanded[record.id],
		}));
	};

	const columns = [
		{
			className: "table_heading",
			title: "Department",
			dataIndex: "departmentName",
			width: 130,
		},
		{
			title: "Project Name",
			dataIndex: "azureProjectName",
			width: 130,
		},
		{
			title: "Year",
			dataIndex: "year",
			width: 70,
		},
		{
			title: "Quarter",
			dataIndex: "monthCycle",
			width: 90,
		},

		{
			title: "Review Date",
			dataIndex: "reviewDate",
			width: 110,
		},
		{
			title: "Code Review",
			dataIndex: "review",
			width: 80,
		},
		{
			title: "Coding Standard",
			dataIndex: "codingStandard",
			width: 110,
		},
		{
			title: "Code Reusability",
			dataIndex: "codeReusability",
			width: 90,
		},
		{
			title: "DevOps",
			dataIndex: "devOps",
			width: 80,
		},
		{
			title: "DevOps Tool",
			dataIndex: "devOpsTool",
			width: 90,
		},
		{
			title: "Reviewer",
			dataIndex: "reviewer",
			width: 100,
		},
		{
			title: "Comment",
			dataIndex: "comment",
			width: 150,
			render: (text, record) => (
				<div
					style={{
						maxHeight: "60px",
						overflowY: "auto",
						wordWrap: "break-word",
					}}>
					{text}
				</div>
			),
		},
		{
			title: "Submitted By",
			dataIndex: "username",
			width: 90,
		},
		{
			title: "Action",
			width: 80,
			render: (data, record) => (
				<span className="Action-button">
					<EditTwoTone twoToneColor="#00ff00" onClick={() => onEdit(record)} />
					&nbsp;&nbsp;
					<DeleteTwoTone
						twoToneColor="#ff0000"
						onClick={() => setDeleteModalData({ show: true, id: data.id })}
					/>
				</span>
			),
		},
	];
	return (
		<div className="table_main">
			<div className="table_container">
				<Spin spinning={loading}>
					<DeleteCmfModal
						onClick={() => onDelete(deleteModalData?.id)}
						visible={deleteModalData?.show}
						onCancel={() => setDeleteModalData({ show: false, id: "" })}
					/>
					<Table
						className="table_main"
						columns={columns.map((column) => ({ ...column, className: "" }))}
						dataSource={props.codeReview}
						pagination={pagination}
						onChange={handleTableChange}
						scroll={{
							x: 1500,
							// y: 500,
						}}
					/>
				</Spin>
				<div
					className={`modal fade bd-example-modal-lg ${
						isModalVisible ? "show" : ""
					}`}
					tabIndex="-1"
					role="dialog"
					style={{ display: isModalVisible ? "block" : "none" }}>
					<div className="modal-dialog modal-lg">
						<div className="modal-content ">
							<div className="modal-header">
								<h5 className="modal-title">Update Customer Satisfaction</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => {
										setIsModalVisible(false);
										setEditingRecord(null);
									}}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{editingRecord && (
									<UpdateCodeReview
										record={editingRecord}
										onUpdate={() => {
											setEditingRecord(false);
										}}
										onUpdateFunction={props.onUpdateFunction}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CodeReviewTable;
