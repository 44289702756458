import React, { useEffect, useState } from "react";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Pages.css";
import TextArea from "../../Components/TextArea";

const initialProjectData = {
	departmentName: "",
	azureProjectName: "",
	// year: new Date(),
	// monthCycle: "",
	monthCycle: { value: "Oct-Dec", label: "Oct-Dec" },
	year: new Date(2023, 0, 1),
	date: "",
	satisfactionLevel: { value: "Average", label: "Average" },
	referenceMail: "",
	comment: "",
};
const initialValidationData = {
	departmentNameError: null,
	azureProjectNameError: null,
	// monthCycleError: null,
	// satisfactionLevelError: null,
	commentError: null,
};

const FormCustomerSatisfaction = (props) => {
	const [projectData, setProjectData] = useState(initialProjectData);
	const [showValidation, setShowValidation] = useState(initialValidationData);
	const [masterData, setMasterData] = useState({
		departments: [],
	});
	const [projectList, setProjectList] = useState([]);

	useEffect(() => {
		const getDepartments = async () => {
			try {
				const allData = await axios.all([
					callAPI("GET", apiEndPoints.getDeptName),
				]);
				if (allData && allData.length > 0) {
					const departments = allData[0].data.data;
					departments.forEach((item) => {
						item.value = item.departmentId;
						item.label = item.departmentName;
						delete item.departmentId;
						delete item.departmentName;
					});
					setMasterData({
						...{ departments },
					});
				}
			} catch (error) {}
		};
		getDepartments();
	}, []);

	useEffect(() => {
		const getProjects = async () => {
			let projectList = [];
			if (projectData.departmentName) {
				const departmentName = projectData.departmentName.label;
				try {
					const resp = await callAPI(
						"GET",
						apiEndPoints.getProjName + "?departmentName=" + departmentName
					);
					projectList = resp.data.data;
					projectList.forEach((item) => {
						item.value = item.azureProjectId;
						item.label = item.azureProjectName;
						delete item.azureProjectId;
						delete item.project;
						delete item.department;
					});
				} catch (err) {
					console.log("Error in fetching Project Names: ", err);
				}
			}
			setProjectList(projectList);
		};
		getProjects();
	}, [projectData.departmentName]);

	const handleProjectData = (val, key) => {
		setProjectData((previousData) => {
			return {
				...previousData,
				[key]: val,
			};
		});
		setShowValidation((previousData) => {
			return {
				...previousData,
				[`${key}Error`]: val ? false : true,
			};
		});
	};

	const clearFormFields = () => {
		setProjectData(initialProjectData);
		setShowValidation(false);
	};

	const clearFormFieldsOnAdd = () => {
		setProjectData({
			departmentName: projectData.departmentName,
			azureProjectName: "",
			monthCycle: projectData.monthCycle,
			date: "",
			year: new Date(2023, 0, 1),
			satisfactionLevel: projectData.satisfactionLevel,
			referenceMail: "",
			comment: "",
		});

		setShowValidation((prev) => {
			return {
				...prev,
				azureProjectNameError: null,
				// satisfactionLevelError: null,
				commentError: null,
			};
		});
	};

	const validationHandler = () => {
		const data = Object.values(showValidation).every((item) => item === false);
		const data1 = { ...projectData };
		const data2 = { ...showValidation };

		for (const key in data1) {
			if (key !== "date" && key !== "referenceMail" && data1[key] === "") {
				data2[`${key}Error`] = true;
			}
		}
		setShowValidation(data2);

		return data;
	};

	const handleSubmit = async () => {
		if (validationHandler() === false) {
			return false;
		}
		try {
			const employeeName = sessionStorage.getItem("employeeName");
			const response = await axios.post(
				apiEndPoints.addRecordInCustomerSatisfaction,
				{
					departmentId: projectData.departmentName.value,
					azureProjectId: projectData.azureProjectName.value,
					monthCycle: projectData.monthCycle.value,
					year: projectData.year.getFullYear(),
					satisfactionLevel: projectData.satisfactionLevel.value,
					referenceMail: projectData.referenceMail.value,
					date: projectData.date,
					comment: projectData.comment,
					username: employeeName,
				}
			);

			clearFormFieldsOnAdd();
			// document.getElementById("exampleModalLong2").click();
			props.fetchCustomerSatisfaction();
			toast.success("Form submitted successfully", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
			});
		} catch (error) {
			if (error.response && error.response.status === 409) {
				const errorMessage = error.response.data.message;
				alert(`${errorMessage}`);
			} else {
				console.error("Error submission", error);
			}
		}
	};

	return (
		<form
			className="modal fade bd-example-modal-lg"
			id="exampleModalLong2"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="myLargeModalLabel"
			aria-hidden="true">
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLongTitle">
							Customer Satisfaction
						</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => {
								clearFormFields();
							}}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<SelectList
							ErrorStyle={showValidation.departmentNameError ? "invalid" : ""}
							label="Department Name"
							id="deptName"
							placeholder="Department Name"
							value={projectData.departmentName}
							onChange={(value) => {
								handleProjectData(value, "departmentName");
							}}
							options={masterData.departments}
							isSearchable={true}
						/>
						<SelectList
							ErrorStyle={showValidation.azureProjectNameError ? "invalid" : ""}
							label="Project Name"
							id="Project"
							placeholder="Project Name"
							value={projectData.azureProjectName}
							onChange={(value) => {
								handleProjectData(value, "azureProjectName");
							}}
							options={projectList}
							isSearchable={true}
						/>
						<DatePickerField
							id="Year"
							label="Year"
							showYearPicker
							dateFormat="yyyy"
							placeholderText="Select Year"
							selected={projectData.year}
							onChange={(date) => {
								handleProjectData(date, "year");
							}}
						/>
					</div>
					<div className="modal-body">
						<SelectList
							ErrorStyle={showValidation.monthCycleError ? "invalid" : ""}
							label="Quarter"
							id="Quarter"
							placeholder="Quarter"
							options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
							defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
							value={projectData.monthCycle}
							onChange={(value) => handleProjectData(value, "monthCycle")}
						/>
						<SelectList
							ErrorStyle={
								showValidation.satisfactionLevelError ? "invalid" : ""
							}
							label="Satisfaction Level"
							id="satisfactionLevel"
							placeholder="Satisfaction Level"
							options={["Good", "Average", "Need Improvement"]}
							value={projectData.satisfactionLevel}
							defaultValue={{ value: "Average", label: "Average" }}
							onChange={(value) =>
								handleProjectData(value, "satisfactionLevel")
							}
						/>
						<SelectList
							label="Reference Mail"
							id="referenceMail"
							placeholder="Reference Mail"
							options={["Yes", "No", "N/A"]}
							value={projectData.referenceMail}
							onChange={(value) => handleProjectData(value, "referenceMail")}
						/>
					</div>
					<div className="modal-body">
						<DatePickerField
							id="date"
							label="Date"
							dateFormat="yyyy-MM-dd"
							placeholderText="Select Date"
							selected={projectData.date}
							onChange={(date) => {
								handleProjectData(date, "date");
							}}
						/>
						<TextArea
							ErrorStyle={showValidation.commentError ? "invalid" : ""}
							label="Comment"
							id="comment"
							rows={1}
							cols={20}
							value={projectData.comment}
							onChange={(e) => handleProjectData(e.target.value, "comment")}
						/>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={handleSubmit}
							className="btn btn-success btn-Add">
							Add
						</button>
						<button
							type="button"
							className="btn btn-primary"
							data-dismiss="modal"
							onClick={() => {
								clearFormFields();
							}}>
							Close
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default FormCustomerSatisfaction;
