import React from "react";

const SelectInputField = ({
  label,
  id,
  options,
  value,
  onChange,
  design,
  defaultValue,
  selected,
}) => {
  return (
    <div className={`form-group col-md-4 ${design}`}>
      <label htmlFor={id}>{label}</label>
      <select
        id={id}
        className="form-control"
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        selected={selected}
        required
      >
        <option value="">Select..</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInputField;
