import React, { useEffect, useState } from "react";
import { Table, Button, Spin } from "antd";
import { EyeOutlined, DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import axios from "axios";
import { apiEndPoints } from "../../constants/apiEndPoints";
import { Form } from "react-bootstrap";
import DatePickerField from "../../Components/DatePickerField";
import SelectInputField from "../../Components/SelectInputField";
import moment from "moment";
import TextInputField from "../../Components/TextInputField";
import { toast } from "react-toastify";
import UpdateProjectProcess from "./UpdateProjectProcess";
import DeleteCmfModal from "../../Components/DeleteCmfModal/DeleteCmfModal";

const TableProjectProcess = (props) => {
	const [editingRecord, setEditingRecord] = useState(null);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
	});
	const [deleteModalData, setDeleteModalData] = useState({
		show: false,
		id: "",
	});

	const [isEyeIconModalVisible, setIsEyeIconModalVisible] = useState(false);
	const [isEditIconModalVisible, setIsEditIconModalVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [modalData, setModalData] = useState(null);
	const [editedRows, setEditedRows] = useState({});
	const [editableRows, setEditableRows] = useState({});
	const [viewProjectData, setViewProjectData] = useState({
		departmentName: "",
		azureProjectName: "",
		year: "",
		quarter: "",
		teamLead: "",
		projectPhase: "",
	});

	useEffect(() => {
		setLoading(true);
		props.fetchProjectProcess(pagination.current, pagination.pageSize);
		setLoading(false);
	}, [props.searchParams]);

	useEffect(() => {
		if (isEyeIconModalVisible || isEditIconModalVisible) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isEyeIconModalVisible, isEditIconModalVisible]);

	const handleTableChange = (pagination) => {
		setPagination(pagination);
	};

	const handleEyeIconClick = async (record) => {
		try {
			setLoading(true);
			const response = await axios.get(
				`${apiEndPoints.getPPCAllData}/${record.id}`
			);
			setEditingRecord(record);
			setModalData(response.data.datalist);
			setIsEyeIconModalVisible(true);
			setIsEditIconModalVisible(false);
			setViewProjectData({
				departmentName: response.data.departmentName,
				azureProjectName: response.data.azureProjectName,
				year: response.data.year,
				quarter: response.data.quarter,
				teamLead: response.data.teamLead,
				projectPhase: response.data.projectPhase,
			});
			setEditableRows({});
		} catch (error) {
			console.error("Error fetching data: ", error);
		} finally {
			setLoading(false);
		}
	};

	const onEdit = (record) => {
		setEditingRecord(record);
		setIsEditIconModalVisible(true);
		setIsEyeIconModalVisible(false);
	};

	const onDelete = async (id) => {
		props.onDeleteFunction(id);
	};

	const toggleEditMode = (record) => {
		setEditableRows((prevEditableRows) => ({
			...prevEditableRows,
			[record.id]: !prevEditableRows[record.id],
		}));
		setEditedRows({});
	};

	const handleDateView = (d) => {
		return moment(d).format("YYYY-MM-DD");
	};

	const handleDate = (d) => {
		if (typeof d == "object") {
			return moment(d).format();
		} else {
			return d;
		}
	};

	const transformDataForServer = (record) => {
		const employeeName = sessionStorage.getItem("employeeName");
		return {
			id: record.id,
			documentTypeId: record.documentTypeId,
			documentType: record.documentType,
			documentName: record.documentName,
			dateOfSubmission: record.dateOfSubmission,
			availableOn: record.availableOn,
			status: record.status,
			updatedDate: record.updatedDate,
			pathFolderLocation: record.pathFolderLocation,
			documentCompliance: record.documentCompliance,
			configurationCompliance: record.configurationCompliance,
			username: employeeName,
		};
	};

	const handleEditInputChange = (value, record, field) => {
		setModalData((prevData) => {
			const newData = [...prevData];
			const index = newData.findIndex((item) => item.id === record.id);
			newData[index][field] = value;

			setEditedRows((prevEditedRows) => ({
				...prevEditedRows,
				[record.id]: true,
			}));
			return newData;
		});
	};

	const handleSaveChanges = async (record) => {
		if (
			record.status === undefined ||
			record.status.trim() === "" ||
			record.documentCompliance === undefined ||
			record.documentCompliance.trim() === "" ||
			record.configurationCompliance === undefined ||
			record.configurationCompliance.trim() === ""
		) {
			toast.error(
				"Status, Document Compliance and Configuration Compliance cannot be empty",
				{
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				}
			);
			return;
		}

		if (editedRows[record.id]) {
			try {
				const transformedData = transformDataForServer(record);
				const response = await axios.post(
					apiEndPoints.updatePPCRecord,
					transformedData
				);

				if (response.status === 200) {
					toast.success("Data has been updated", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 2000,
					});
					setModalData((prevData) => {
						const newData = [...prevData];
						const index = newData.findIndex((item) => item.id === record.id);
						newData[index].username = transformedData.username;
						return newData;
					});
				}
			} catch (error) {
				console.error("ERROR: ", error);
			}

			setEditedRows((prevEditedRows) => ({
				...prevEditedRows,
				[record.id]: false,
			}));
		}
		toggleEditMode(record);
	};

	const columns = [
		{
			className: "table_heading",
			title: "Department",
			dataIndex: "departmentName",
			width: 120,
		},
		{
			title: "Project",
			dataIndex: "azureProjectName",
			width: 120,
		},
		{
			title: "Year",
			dataIndex: "year",
			width: 70,
		},
		{
			title: "Quarter",
			dataIndex: "quarter",
			width: 70,
		},
		{
			title: "Action",
			width: 80,
			render: (data, record) => (
				<span className="Action-button">
					<EyeOutlined
						twoToneColor="#00ff00"
						onClick={() => handleEyeIconClick(record)}
					/>
					&nbsp;&nbsp;
					<EditTwoTone twoToneColor="#00ff00" onClick={() => onEdit(record)} />
					&nbsp;&nbsp;
					<DeleteTwoTone
						twoToneColor="#ff0000"
						onClick={() => setDeleteModalData({ show: true, id: data.id })}
					/>
				</span>
			),
		},
	];

	const modalColumns = [
		{
			title: "Document Type",
			dataIndex: "documentType",
			width: 90,
			key: "documentType",
			render: (text, record) => <span>{text}</span>,
		},
		{
			title: "Document Name",
			width: 90,
			dataIndex: "documentName",
			key: "documentName",
			render: (text, record) =>
				editableRows[record.id] ? (
					<TextInputField
						design="design"
						value={text}
						onChange={(e) =>
							handleEditInputChange(e.target.value, record, "documentName")
						}
					/>
				) : (
					<span>{text}</span>
				),
		},
		{
			title: "Date Of Submission",
			dataIndex: "dateOfSubmission",
			key: "dateOfSubmission",
			width: 90,
			render: (text, record) =>
				editableRows[record.id] ? (
					<DatePickerField
						design="design"
						selected={record.dateOfSubmission}
						value={text ? handleDateView(text) : null}
						onChange={(date) =>
							handleEditInputChange(
								handleDate(date),
								record,
								"dateOfSubmission"
							)
						}
					/>
				) : text ? (
					handleDateView(text)
				) : null,
		},
		{
			title: "Available On",
			dataIndex: "availableOn",
			key: "availableOn",
			width: 90,
			render: (text, record) =>
				editableRows[record.id] ? (
					<SelectInputField
						design="design"
						value={text}
						onChange={(e) =>
							handleEditInputChange(e.target.value, record, "availableOn")
						}
						options={["Jira", "TFS", "SVN", "DevOps"]}
					/>
				) : (
					text
				),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 90,
			render: (text, record) =>
				editableRows[record.id] ? (
					<SelectInputField
						design="design"
						value={text}
						onChange={(e) =>
							handleEditInputChange(e.target.value, record, "status")
						}
						options={["Completed", "Pending", "Approved", "N/A"]}
					/>
				) : (
					text
				),
		},
		{
			title: "Updated Date",
			dataIndex: "updatedDate",
			key: "updatedDate",
			width: 90,
			render: (text, record) =>
				editableRows[record.id] ? (
					<DatePickerField
						design="design"
						value={text ? handleDateView(text) : null}
						onChange={(date) =>
							handleEditInputChange(handleDate(date), record, "updatedDate")
						}
					/>
				) : text ? (
					handleDateView(text)
				) : null,
		},
		{
			title: "Path Folder Location",
			dataIndex: "pathFolderLocation",
			key: "pathFolderLocation",
			width: 90,
			render: (text, record) =>
				editableRows[record.id] ? (
					<TextInputField
						design="design"
						value={text}
						onChange={(e) =>
							handleEditInputChange(
								e.target.value,
								record,
								"pathFolderLocation"
							)
						}
					/>
				) : (
					<span>{text}</span>
				),
		},
		{
			title: "Document Compliance",
			dataIndex: "documentCompliance",
			key: "documentCompliance",
			width: 90,
			render: (text, record) =>
				editableRows[record.id] ? (
					<SelectInputField
						value={text}
						design="design"
						onChange={(e) =>
							handleEditInputChange(
								e.target.value,
								record,
								"documentCompliance"
							)
						}
						options={["Yes", "No", "N/A"]}
					/>
				) : (
					text
				),
		},
		{
			title: "Configuration Compliance",
			dataIndex: "configurationCompliance",
			key: "configurationCompliance",
			width: 60,
			render: (text, record) =>
				editableRows[record.id] ? (
					<SelectInputField
						value={text}
						design="design"
						onChange={(e) =>
							handleEditInputChange(
								e.target.value,
								record,
								"configurationCompliance"
							)
						}
						options={["Yes", "No", "N/A"]}
					/>
				) : (
					text
				),
		},
		{
			title: "Submitted By",
			dataIndex: "username",
			width: 90,
		},
		{
			title: "Action",
			width: 100,
			render: (text, record) => (
				<span>
					{editableRows[record.id] ? (
						<>
							<Button type="link" onClick={() => handleSaveChanges(record)}>
								Save
							</Button>
							&nbsp;&nbsp;
						</>
					) : (
						<Button type="link" onClick={() => toggleEditMode(record)}>
							Edit
						</Button>
					)}
				</span>
			),
		},
	];

	return (
		<div>
			<div className="table_main view-ALM mt-2 ">
				<div className="table-container">
					<Spin spinning={loading}>
						<DeleteCmfModal
							onClick={() => onDelete(deleteModalData?.id)}
							visible={deleteModalData?.show}
							onCancel={() => setDeleteModalData({ show: false, id: "" })}
						/>
						<Table
							className="table_main"
							columns={columns.map((column) => ({ ...column, className: "" }))}
							dataSource={props.projectProcess}
							pagination={pagination}
							onChange={handleTableChange}
							scroll={{
								x: 900,
							}}
						/>
					</Spin>
					{isEyeIconModalVisible && (
						<div
							className={`modal fade bd-example-modal-lg modal-overlay  aria-hidden="true" ${
								isEyeIconModalVisible ? "show" : ""
							}`}
							tabIndex="-1"
							role="dialog"
							style={{ display: isEyeIconModalVisible ? "block" : "none" }}
							data-backdrop="static"
							data-keyboard="false">
							<div className="modal-dialog modal-lg view-modal-projectProcess ">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">Project Process Compliance</h5>
										<button
											type="button"
											className="close"
											data-dismiss="modal"
											aria-label="Close"
											onClick={() => {
												setIsEyeIconModalVisible(false);
												setEditingRecord(null);
												setModalData(null);
											}}>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body d-block view-table update_PPC">
										<div className="view-top-content row">
											<Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="departmentName">
												<Form.Label>Department Name:</Form.Label>
												<Form.Control
													disabled
													type="text"
													value={viewProjectData.departmentName}
												/>
											</Form.Group>
											<Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="azureProjectName">
												<Form.Label>Project Name:</Form.Label>
												<Form.Control
													disabled
													type="text"
													value={viewProjectData.azureProjectName}
												/>
											</Form.Group>
											<Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="year">
												<Form.Label>Year:</Form.Label>
												<Form.Control
													disabled
													type="year"
													value={viewProjectData.year}
												/>
											</Form.Group>
											<Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="quarter">
												<Form.Label>Quarter:</Form.Label>
												<Form.Control
													disabled
													type="text"
													value={viewProjectData.quarter}></Form.Control>
											</Form.Group>
											<Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="teamLead">
												<Form.Label>Team Lead:</Form.Label>
												<Form.Control
													disabled
													type="text"
													value={viewProjectData.teamLead}></Form.Control>
											</Form.Group>
											<Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="projectPhase">
												<Form.Label>Project Phase:</Form.Label>
												<Form.Control
													disabled
													type="text"
													value={viewProjectData.projectPhase}></Form.Control>
											</Form.Group>
										</div>
										{modalData &&
										Array.isArray(modalData) &&
										modalData.length > 0 ? (
											<Table
												scroll={{
													x: 1500,
													// y: 500,
												}}
												columns={modalColumns.map((column) => ({
													...column,
													className: "",

													render:
														typeof column.render === "function"
															? column.render
															: undefined,
												}))}
												dataSource={modalData.map((item) => ({
													...item,
													key: item.id,
												}))}
											/>
										) : (
											<p>No Data Available</p>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					{isEditIconModalVisible && (
						<UpdateProjectProcess
							record={editingRecord}
							onUpdate={() => {
								setIsEditIconModalVisible(false);
								setEditingRecord(null);
							}}
							onUpdateFunction={props.onUpdateFunction}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default TableProjectProcess;
