export const apiEndPoints = {
  getDeptName:
    "https://bvauditapi.brainvire.net/api/Department/GetAllDepartmentName",
  getProjName:
    "https://bvauditapi.brainvire.net/api/Project/GetProjectByDepartment",
  getDocType:
    "https://bvauditapi.brainvire.net/api/DocumentType/GetDocumentTypes",
  getProjectPhases:
    "https://bvauditapi.brainvire.net/api/ProjectPhase/GetProjectPhases",

  //Customer Satisfaction APIs

  getCustomerSatisfactionRecord:
    "https://bvauditapi.brainvire.net/api/CustomerSatisfaction/GetCustomerSatisfactionrecord",
  addRecordInCustomerSatisfaction:
    "https://bvauditapi.brainvire.net/api/CustomerSatisfaction/AddRecordInCustomerSatisfaction",
  updateCustomerSatisfactionRecord:
    "https://bvauditapi.brainvire.net/api/CustomerSatisfaction/UpdateCustomerSatisfactionRecord",
  deleteCustomerSatisfactionRecord:
    "https://bvauditapi.brainvire.net/api/CustomerSatisfaction/DeleteCustomerSatisfactionRecord/",
  exportCSData:
    "https://bvauditapi.brainvire.net/api/CustomerSatisfaction/ExportCSData",

  //Code Review APIs

  getBugCountCR: "https://bvauditapi.brainvire.net/api/CodeReview/GetBugCount",
  getCodeReviewRecord:
    "https://bvauditapi.brainvire.net/api/CodeReview/GetCodeReviewRecord",
  addCodeReviewRecord:
    "https://bvauditapi.brainvire.net/api/CodeReview/AddCodeReviewRecord",
  updateCodeReviewRecord:
    "https://bvauditapi.brainvire.net/api/CodeReview/UpdateCodeReviewRecord",
  deleteCodeReviewRecord:
    "https://bvauditapi.brainvire.net/api/CodeReview/DeleteCodeReviewRecord/",
  exportCRData: "https://bvauditapi.brainvire.net/api/CodeReview/ExportCRData",

  //Project Effort APIs

  getProjectEffortVarianceRecord:
    "https://bvauditapi.brainvire.net/api/ProjectEffortVarianceReport/GetProjectEffortVarianceReportsRecord",
  addProjectEffortVarianceRecord:
    "https://bvauditapi.brainvire.net/api/ProjectEffortVarianceReport/AddRecordInProjectEffortVarianceReport",
  updateProjectEffortVarianceRecord:
    "https://bvauditapi.brainvire.net/api/ProjectEffortVarianceReport/UpdateProjectEffortVarianceReport",
  deleteProjectEffortVarianceRecord:
    "https://bvauditapi.brainvire.net/api/ProjectEffortVarianceReport/DeleteProjectEffortVarianceReport/",
  downloadProjectEffortVariance:
    "https://bvauditapi.brainvire.net/api/ProjectEffortVarianceReport/DownloadProjectEffortVariance",

  //Project Plan APIs

  getProjectPlanReportRecord:
    "https://bvauditapi.brainvire.net/api/ProjectPlanReport/GetProjectPlanReportRecord",
  addRecordInProjectPlanReport:
    "https://bvauditapi.brainvire.net/api/ProjectPlanReport/AddRecordInProjectPlanReport",
  updateProjectPlanReportRecord:
    "https://bvauditapi.brainvire.net/api/ProjectPlanReport/UpdateProjectPlanReportRecord",
  deleteProjectPlanReportRecord:
    "https://bvauditapi.brainvire.net/api/ProjectPlanReport/DeleteProjectPlanReportRecord/",
  downloadProjectPlanReport:
    "https://bvauditapi.brainvire.net/api/ProjectPlanReport/DownloadProjectPlanReport",

  //Tracking Change APIs

  getCRData:
    "https://bvauditapi.brainvire.net/api/TrakingChangeRequest/GetCRSummery",
  getChangeRequestData:
    "https://bvauditapi.brainvire.net/api/TrakingChangeRequest/GetCrRequestStatus",
  getTrackingChangeRequest:
    "https://bvauditapi.brainvire.net/api/TrakingChangeRequest/GetTrackingChangeRequest",
  addTrackingChangeRequest:
    "https://bvauditapi.brainvire.net/api/TrakingChangeRequest/AddTrackingChangeRequest",
  updateTrackingChangeRequest:
    "https://bvauditapi.brainvire.net/api/TrakingChangeRequest/UpdateTrackingChangeRequest",
  deleteTrackingChangeRequest:
    "https://bvauditapi.brainvire.net/api/TrakingChangeRequest/DeleteTrackingChangeRequest/",
  downloadTrackingChangeRequest:
    "https://bvauditapi.brainvire.net/api/TrakingChangeRequest/DownloadTrackingChangeRequest",

  //Automation Security APIs

  getAutomationSecurityAndPerformance:
    "https://bvauditapi.brainvire.net/api/AutomationSecurityAndPerformance/GetAutomationSecurityAndPerformance",
  addAutomationSecurityAndPerformance:
    "https://bvauditapi.brainvire.net/api/AutomationSecurityAndPerformance/AddAutomationSecurityAndPerformance",
  updateAutomationSecurityAndPerformance:
    "https://bvauditapi.brainvire.net/api/AutomationSecurityAndPerformance/UpdateAutomationSecurityAndPerformance",
  deleteAutomationSecurityAndPerformance:
    "https://bvauditapi.brainvire.net/api/AutomationSecurityAndPerformance/DeleteAutomationSecurityAndPerformance/",
  downloadAutomationSecurityAndPerformance:
    "https://bvauditapi.brainvire.net/api/AutomationSecurityAndPerformance/DownloadAutomationSecurityAndPerformance",

  //Quality of Development APIs

  getBugsPerHour:
    "https://bvauditapi.brainvire.net/api/QualityOfDevelopment/GetBugPerHour",
  getDefectDensity:
    "https://bvauditapi.brainvire.net/api/QualityOfDevelopment/GetDefectDensity",
  getQualityOfDevelopmentRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfDevelopment/GetQualityOfDevelopmentRecord",
  addQualityOfDevelopmentRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfDevelopment/AddQualityOfDevelopmentRecord",
  updateQualityOfDevelopmentRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfDevelopment/UpdateQualityOfDevelopmentRecord",
  deleteQualityOfDevelopmentRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfDevelopment/DeleteQualityOfDevelopmentRecord/",
  exportQODData:
    "https://bvauditapi.brainvire.net/api/QualityOfDevelopment/ExportQODData",

  //Quality Of Testing APIs

  getBugCount:
    "https://bvauditapi.brainvire.net/api/QualityOfTesting/GetBugCount",
  getRating: "https://bvauditapi.brainvire.net/api/QualityOfTesting/GetRating",
  addQualityOfTestingRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfTesting/AddQualityOfTestingRecord",
  getQualityOfTestingRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfTesting/GetQualityOfTestingRecord",
  updateQualityOfTestingRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfTesting/UpdateQualityOfTestingRecord",
  deleteQualityOfTestingRecord:
    "https://bvauditapi.brainvire.net/api/QualityOfTesting/DeleteQualityOfTestingRecord/",
  exportQOTData:
    "https://bvauditapi.brainvire.net/api/QualityOfTesting/ExportQOTData",

  //Architecture Approval APIs

  getArchitectureApproval:
    "https://bvauditapi.brainvire.net/api/ArchitectureApproval/GetArchitectureApproval",
  addArchitectureApproval:
    "https://bvauditapi.brainvire.net/api/ArchitectureApproval/AddArchitectureApproval",
  updateArchitectureApproval:
    "https://bvauditapi.brainvire.net/api/ArchitectureApproval/UpdateArchitectureApproval",
  deleteArchitectureApproval:
    "https://bvauditapi.brainvire.net/api/ArchitectureApproval/DeleteArchitectureApproval/",
  downloadArchitectureApproval:
    "https://bvauditapi.brainvire.net/api/ArchitectureApproval/DownloadArchitectureApproval",

  //Application Life Cycle APIs

  addALMRecordDraft:
    "https://bvauditapi.brainvire.net/api/ALM/AddALMRecordDraft",
  addALMRecord: "https://bvauditapi.brainvire.net/api/ALM/AddALMRecord",
  getALMTableData: "https://bvauditapi.brainvire.net/api/ALM/GetALMTableData",
  getApplicationData:
    "https://bvauditapi.brainvire.net/api/ALM/GetApplicationData",
  deleteALMRecord: "https://bvauditapi.brainvire.net/api/ALM/DeleteALMRecord/",
  updateALMRecord: "https://bvauditapi.brainvire.net/api/ALM/UpdateALMRecord",
  updateALMMasterRecord:
    "https://bvauditapi.brainvire.net/api/ALM/UpdateALMMasterRecord",
  downloadALM: "https://bvauditapi.brainvire.net/api/ALM/DownloadALM",

  //Project Process APIs (New)

  getPPCTableData:
    "https://bvauditapi.brainvire.net/api/ProjectProcessCompliance/GetPPCtableData",
  addPPCRecord:
    "https://bvauditapi.brainvire.net/api/ProjectProcessCompliance/AddPPCRecord",
  getPPCAllData:
    "https://bvauditapi.brainvire.net/api/ProjectProcessCompliance/GetPPCAllData",
  updatePPCRecord:
    "https://bvauditapi.brainvire.net/api/ProjectProcessCompliance/UpdatePPCRecord",
  updatePPCMasterRecord:
    "https://bvauditapi.brainvire.net/api/ProjectProcessCompliance/UpdatePPCMasterRecord",
  deletePPCRecord:
    "https://bvauditapi.brainvire.net/api/ProjectProcessCompliance/DeletePPCRecord/",
  exportPPCData:
    "https://bvauditapi.brainvire.net/api/ProjectProcessCompliance/ExportPPCData",

  //Login API:

  logOnESS: "https://bvauditapi.brainvire.net/api/Account/LogOness",
};
