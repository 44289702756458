import React from "react";
import { Link } from "react-router-dom";
import "./TopNavigation.css";

const TopNavigation = ({ loggedIn, onLogout }) => {
  return (
    <nav className="navigation-bar">
      <h3>
        <Link to="/">BV Audit</Link>
      </h3>
      <div className="nav-contents">
        {loggedIn && (
          <div className="user-info">
            <span>{sessionStorage.getItem("employeeName")}</span>
            <br />
            <span>{sessionStorage.getItem("designationName")}</span>
          </div>
        )}
        {loggedIn && (
          <button className="btn logout-button" onClick={onLogout}>
            Logout
          </button>
        )}
      </div>
    </nav>
  );
};

export default TopNavigation;
