import axios from "axios";

export const exportDataToExcel = async (exportParams, apiEndPoints) => {
  try {
    // Fetch the data from the API using the exportParams
    const response = await axios.get(apiEndPoints, {
      params: exportParams,
    });

    if (response.status === 200) {
      const filePath = response.data.path;
      const downloadUrl = filePath;

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("API Request Error:", response.statusText);
    }
  } catch (error) {
    console.error("Error exporting data to Excel: ", error);
  }
};
