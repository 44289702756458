import React, { useEffect, useState } from "react";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import SelectInputField from "../../Components/SelectInputField";
import "../Pages.css";
import TableTrackingChange from "./TableTrackingChange";
import FormTrackingChange from "./FormTrackingChange";
import { exportDataToExcel } from "../../utils/excelExportUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { Spin } from "antd";

const TrackingChange = (props) => {
	const [projectData, setProjectData] = useState({
		pageNumber: 1,
		departmentName: "",
		project: "",
		monthCycle: "",
		year: "",
	});
	const [trackingChange, setTrackingChange] = useState([]);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 5,
	});
	const [loadingExport, setLoadingExport] = useState(false);
	const [loadingSearch, setLoadingSearch] = useState(false);
	const [recordCount, setRecordCount] = useState(0);
	const [masterData, setMasterData] = useState({
		departments: [],
	});
	const [projectList, setProjectList] = useState([]);
	useEffect(() => {
		const getDepartments = async () => {
			try {
				const allData = await axios.all([
					callAPI("GET", apiEndPoints.getDeptName),
				]);

				if (allData && allData.length > 0) {
					const departments = allData[0].data.data;

					departments.forEach((item) => {
						item.value = item.departmentId;
						item.label = item.departmentName;
						delete item.departmentId;
						delete item.departmentName;
					});

					setMasterData({
						...{ departments },
					});
				}
			} catch (error) {}
		};
		getDepartments();
	}, []);

	useEffect(() => {
		const getProjects = async () => {
			let projectList = [];
			if (projectData.departmentName) {
				const departmentName = projectData.departmentName.label;
				try {
					const resp = await callAPI(
						"GET",
						apiEndPoints.getProjName + "?departmentName=" + departmentName
					);
					projectList = resp.data.data;
					projectList.forEach((item) => {
						item.value = item.azureProjectId;
						item.label = item.azureProjectName;
						delete item.azureProjectId;
						delete item.project;
						delete item.department;
					});
				} catch (err) {
					console.log("Error in fetching Project Names: ", err);
				}
			}
			setProjectList(projectList);
		};
		getProjects();
	}, [projectData.departmentName]);

	const handleProjectData = (evt, key, val) => {
		const value = evt && evt.target ? evt.target.value : val;

		if (key === "departmentName" && projectData.project) {
			setProjectData({
				...projectData,
				project: "",
				...{ [key]: value },
			});
		} else {
			setProjectData({
				...projectData,
				[key]: value,
			});
		}
	};

	const handleSearch = async () => {
		const searchParams = { ...projectData };

		for (const key in searchParams) {
			if (searchParams[key] === "" || searchParams[key] === undefined) {
				delete searchParams[key];
			}
		}

		if (searchParams.departmentName) {
			searchParams.departmentName = searchParams.departmentName.label;
		} else {
			delete searchParams.departmentName;
		}

		if (searchParams.project) {
			searchParams.project = searchParams.project.label;
		} else {
			delete searchParams.project;
		}

		if (searchParams.year !== "") {
			const yearValue = new Date(searchParams.year).getFullYear();
			if (!isNaN(yearValue)) {
				searchParams.year = yearValue.toString();
			} else {
				delete searchParams.year;
			}
		} else {
			delete searchParams.year;
		}

		if (!searchParams.monthCycle) {
			delete searchParams.monthCycle;
		}

		try {
			setLoadingSearch(true);
			const response = await axios.get(apiEndPoints.getTrackingChangeRequest, {
				params: {
					...searchParams,
					pageNumber: 1,
					pageSize: pagination.pageSize,
				},
			});
			const data = response.data.data;

			setTimeout(() => {
				setTrackingChange(data);
				setRecordCount(response.data.count);
				setLoadingSearch(false);
			}, 200);
		} catch (error) {
			console.error("Error fetching Table Data: ", error);
		}
	};

	const fetchTrackingChange = async (page, pageSize) => {
		const { searchParams } = props; //destructuring
		let urlParams = "";
		for (let param in searchParams) {
			if (urlParams !== "") urlParams += "&";
			urlParams += `${param}=${searchParams[param]}`;
		}
		if (urlParams !== "") urlParams = "?" + urlParams;

		try {
			const response = await fetch(
				apiEndPoints.getTrackingChangeRequest + urlParams,
				{
					params: {
						pageNumber: page,
						pageSize: pageSize,
					},
				}
			);
			const data = await response.json();
			setTrackingChange(data.data);

			setPagination((prevPagination) => ({
				...prevPagination,
				current: page,
				pageSize,
			}));
			setRecordCount(data.count);
		} catch (error) {
			console.error("Error fetching Table Data: ", error);
		}
	};

	const handleExportToExcel = async () => {
		if (
			!projectData.departmentName ||
			!projectData.monthCycle ||
			!projectData.year
		) {
			alert(
				"Please fill in Department Name, Quarter, and Year before exporting."
			);
			return;
		}
		setLoadingExport(true);

		const exportParams = {
			departmentName: projectData.departmentName
				? projectData.departmentName.label
				: "",
			monthCycle: projectData.monthCycle || "",
			year:
				projectData.year instanceof Date ? projectData.year.getFullYear() : "",
		};

		try {
			await exportDataToExcel(
				exportParams,
				apiEndPoints.downloadTrackingChangeRequest
			);
		} catch (error) {
			console.error("Error during export:", error);
		} finally {
			setLoadingExport(false);
		}
	};

	const deleteData = async (id) => {
		try {
			await axios.delete(`${apiEndPoints.deleteTrackingChangeRequest}${id}`);
			toast.error(" Deleted successfully", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
			});
			handleSearch();
		} catch (error) {}
	};

	return (
		<>
			<ToastContainer />

			<div className="page-content">
				<div className="page-header">
					<h1>Tracking Change Request</h1>
				</div>
				<div className="page-container">
					<div className="form-row">
						<SelectList
							label="Department Name"
							id="deptName"
							placeholder="Department Name"
							value={projectData.departmentName}
							onChange={(value, e) => {
								handleProjectData(e, "departmentName", value);
							}}
							options={masterData.departments}
							isSearchable={true}
						/>
						<SelectList
							label="Project Name"
							id="Project"
							placeholder="Project Name"
							value={projectData.project}
							onChange={(value, e) => {
								handleProjectData(e, "project", value);
							}}
							options={projectList}
							isSearchable={true}
						/>
					</div>
					<div className="form-row">
						<DatePickerField
							id="projYear"
							label="Select Year"
							showYearPicker
							dateFormat="yyyy"
							placeholderText="Select Year"
							selected={projectData.year}
							onChange={(date, evt) => {
								handleProjectData(evt, "year", date);
							}}
						/>
						<SelectInputField
							label="Select Quarter"
							id="Quarter"
							options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
							value={projectData.monthCycle}
							onChange={(e) => handleProjectData(e, "monthCycle")}
						/>
					</div>
					<div className="action-button">
						<div className="left-action-button">
							<div>
								<button
									type="button"
									className="btn btn-success btn-Add"
									data-toggle="modal"
									data-target="#exampleModalLong2">
									Add
								</button>
								<FormTrackingChange
									fetchTrackingChange={fetchTrackingChange}
									trackingChange={trackingChange}
								/>
								&nbsp;
							</div>
							<div>
								<button
									type="button"
									className={`btn btn-primary ${
										loadingSearch ? "loading" : ""
									}`}
									onClick={handleSearch}
									disabled={loadingSearch}>
									{loadingSearch ? (
										<Spin style={{ marginRight: 8 }} />
									) : (
										<>Search</>
									)}
								</button>
								&nbsp;
								<button
									type="button"
									className="btn btn-secondary"
									onClick={() => (
										setProjectData({
											pageNumber: 1,
											departmentName: "",
											project: "",
											monthCycle: "",
											year: "",
										}),
										fetchTrackingChange()
									)}>
									Clear
								</button>
							</div>
						</div>
						<div className="right-action-button">
							<div className="countNumber border mt-20px">
								<label>
									Total Projects :- <span className="ml-1">{recordCount}</span>{" "}
								</label>
							</div>
							<div className="export">
								<button
									type="button"
									className={`btn-export ${loadingExport ? "loading" : ""}`}
									onClick={handleExportToExcel}
									disabled={loadingExport}>
									{loadingExport ? (
										<Spin style={{ marginRight: 8 }} /> 
									) : (
										<>
											Export &nbsp;
											<FontAwesomeIcon icon={faFileExport} />{" "}
										</>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="Table">
					<TableTrackingChange
						fetchTrackingChange={fetchTrackingChange}
						trackingChange={trackingChange}
						onDeleteFunction={deleteData}
						onUpdateFunction={handleSearch}
					/>
				</div>
			</div>
		</>
	);
};

export default TrackingChange;
