import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { apiEndPoints } from "../../constants/apiEndPoints";
import DatePickerField from "../../Components/DatePickerField";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateProjectProcess = ({ record, onUpdate, onUpdateFunction }) => {
  const [editedRecord, setEditedRecord] = useState({
    ...record,
    year: new Date(record.year.toString()),
  });

  const [showValidation, setShowValidation] = useState({
    monthCycleError: "",
  });

  const handleFieldChange = (fieldName, value) => {
    const updatedRecord = { ...editedRecord };
    if (fieldName === "dateformat") {
      updatedRecord.date = formatDateToYYYYMMDD(value);
    } else {
      updatedRecord[fieldName] = value;
    }

    const newValidationState = { ...showValidation };

    if (fieldName === "quarter") {
      newValidationState.monthCycleError = value
        ? ""
        : "This field is required.";
    }
    setShowValidation(newValidationState);
    setEditedRecord(updatedRecord);
  };

  const formatDateToYYYYMMDD = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleOnChange = (fieldName, date) => {
    setEditedRecord((state) => ({
      ...state,
      [fieldName]: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleUpdate = async () => {
    const isFormValid = Object.values(showValidation).every(
      (error) => error === ""
    );
    if (!isFormValid) {
      return;
    }
    try {
      const response = await axios.post(apiEndPoints.updatePPCMasterRecord, {
        id: editedRecord.id,
        departmentId: editedRecord.departmentId,
        azureProjectId: editedRecord.azureProjectId,
        quarter: editedRecord.quarter,
        year: editedRecord?.year.getFullYear(),
        teamLead: editedRecord.teamLead,
        projectPhaseId: editedRecord.projectPhaseId,
      });
      if (response.status === 200) {
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        onUpdateFunction();
        onUpdate();
      }
    } catch (error) {}
  };

  return (
    <Modal
      className="modal fade bd-example-modal-lg PPC-master-update"
      show={true}
      onHide={onUpdate}
      centered
      size="lg"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title>Update Project Process Compliance</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body ">
        <Form style={{ width: "100%" }}>
          <div className="row">
            <Form.Group
              className="form-group col-md-6"
              controlId="departmentName"
            >
              <Form.Label>Department Name:</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={editedRecord.departmentName}
                onChange={(e) =>
                  handleFieldChange("departmentName", e.target.value)
                }
              />
            </Form.Group>
            <Form.Group
              className="form-group col-md-6"
              controlId="azureProjectName"
            >
              <Form.Label>Project:</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={editedRecord.azureProjectName}
                onChange={(e) =>
                  handleFieldChange("azureProjectName", e.target.value)
                }
              />
            </Form.Group>
          </div>
          <div className="row">
            <DatePickerField
              className="form-control "
              label="Year:"
              type="year"
              showYearPicker
              selected={editedRecord?.year ?? new Date()}
              dateFormat="yyyy"
              onChange={(year) => handleFieldChange("year", year)}
            />
            <Form.Group className="form-group col-md-6" controlId="monthCycle">
              <Form.Label>Quarter:</Form.Label>
              <Form.Control
                as="select"
                value={editedRecord.quarter}
                className={showValidation.quarter ? "invalid" : ""}
                onChange={(e) => handleFieldChange("quarter", e.target.value)}
              >
                <option value="Jan-Mar">Jan-Mar</option>
                <option value="Apr-Jun">Apr-Jun</option>
                <option value="July-Sep">July-Sep</option>
                <option value="Oct-Dec">Oct-Dec</option>
              </Form.Control>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
        <Button variant="secondary" onClick={onUpdate}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProjectProcess;
