import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import TopNavigation from "../../Navigation/TopNavigation";
import axios from "axios";
import { apiEndPoints } from "../../constants/apiEndPoints";
import { Spin } from "antd";

const Login = ({ setLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await axios.post(apiEndPoints.logOnESS, {
        userName: username,
        password: password,
      });

      if (response.data.success) {
        setLoggedIn(true);
        navigate("/CustomerSatisfaction");
        sessionStorage.setItem("loggedIn", "true");

        const { employeeName, designationName } = response.data.data.result[0];
        sessionStorage.setItem("employeeName", employeeName);
        sessionStorage.setItem("designationName", designationName);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log("Error: ", error);
      alert("An error occurred during login. Please try again later.");
    } finally {
      setLoading(false);
    }

    // if (username === "bva" && password === "1234") {
    //   setLoggedIn(true);
    //   navigate("/Home");
    // } else {
    //   alert("Invalid Username & Password");
    // }
    // sessionStorage.setItem("loggedIn", "true");
  };

  return (
    <>
      <div className="login__header">
        <TopNavigation />
      </div>

      <div className="login-main-container">
        <div className="login-content mt-5">
          <h3>BV Audit</h3>

          <form className="login-form">
            <div className="widget-header">
              <h6 className="h6">Login</h6>
            </div>
            <div className=" row form-content ">
              <div className="form-group col-sm-12">
                <label className="col-sm-4">Username:</label>
                <input
                  className="col-sm-6"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="form-group col-sm-12">
                <label className="col-sm-4">Password:</label>
                <input
                  className="col-sm-6"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="form-group col-sm-12 justify-content-center mt-4">
                <button
                  type="submit"
                  className="login-submit btn btn-success btn-Add"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {/* Login */}
                  {loading ? <Spin style={{ color: "white" }} /> : "Login"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
