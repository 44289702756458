import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";

const DatePickerField = ({
  label,
  selected,
  onChange,
  id,
  dateFormat,
  required,
  design,
  ...moreProps
}) => {
  useEffect(() => {
    setLocalSelectedDate(selected instanceof Date ? selected : null);
  }, [selected]);

  const [localSelectedDate, setLocalSelectedDate] = useState(null);

  useEffect(() => {
    setLocalSelectedDate(selected instanceof Date ? selected : null);
  }, [selected]);

  return (
    <div className={`form-group col-md-4 date__picker ${design}`}>
      <label htmlFor={id}>{label}</label>
      <div className="input-group">
        <DatePicker
          id={id}
          selected={localSelectedDate}
          onChange={(date) => {
            setLocalSelectedDate(date);
            onChange(date);
          }}
          className="form-control"
          dateFormat={dateFormat}
          {...moreProps}
        />
        <span className="input-group-addon">
          <i>
            <label htmlFor={id}>
              <FaCalendarAlt />
            </label>
          </i>
        </span>
      </div>
    </div>
  );
};

export default DatePickerField;
