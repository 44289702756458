import React, { useEffect, useState } from "react";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import "../Pages.css";
import TextInputField from "../../Components/TextInputField";
import TextArea from "../../Components/TextArea";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FormTrackingChange = (props) => {
  const initialProjectData = {
    departmentName: "",
    azureProjectName: "",
    // monthCycle: "",
    // year: new Date(),
    monthCycle: { value: "Oct-Dec", label: "Oct-Dec" },
    year: new Date(2023, 0, 1),
    changeRequest: "",
    crNo: "",
    estimatedHours: "",
    approvedHours: "",
    notApprovedHours: "",
    approved: "",
    notApproved: "",
    comment: "",
  };
  const [projectData, setProjectData] = useState(initialProjectData);
  const [masterData, setMasterData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showValidation, setShowValidation] = useState({
    departmentNameError: null,
    azureProjectNameError: null,
    // monthCycleError: null,
    approvedError: null,
    notApprovedError: null,
    commentError: null,
    changeRequestError: null,
    crNoError: null,
    estimatedHoursError: null,
    approvedHoursError: null,
    notApprovedHoursError: null,
  });

  const handleProjectData = (val, key) => {
    setProjectData((previousData) => {
      return {
        ...previousData,
        [key]: val,
      };
    });
    setShowValidation((previousData) => {
      return {
        ...previousData,
        [`${key}Error`]: val ? false : true,
      };
    });
  };

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
        ]);
        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;
          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });
          setMasterData({
            ...{ departments },
          });
        }
      } catch (error) {}
    };
    getDepartments();
  }, []);

  useEffect(() => {
    const getProjects = async () => {
      let projectList = [];
      if (projectData.departmentName) {
        const departmentName = projectData.departmentName.label;
        try {
          const resp = await callAPI(
            "GET",
            apiEndPoints.getProjName + "?departmentName=" + departmentName
          );
          projectList = resp.data.data;
          projectList.forEach((item) => {
            item.value = item.azureProjectId;
            item.label = item.azureProjectName;
            delete item.azureProjectId;
            delete item.project;
            delete item.department;
          });
        } catch (err) {
          console.log("Error in fetching Project Names: ", err);
        }
      }
      setProjectList(projectList);
    };
    getProjects();
  }, [projectData.departmentName]);

  useEffect(() => {
    props.fetchTrackingChange();
  }, []);

  useEffect(() => {
    const fetchCRData = async () => {
      const { azureProjectName, monthCycle, year } = projectData;
      try {
        setLoading(true);
        const response = await axios.post(
          `${apiEndPoints.getCRData}?AzureProjectId=${
            azureProjectName.value
          }&quarter=${monthCycle.value}&year=${year.getFullYear()}`
        );
        const crData = response.data;
        if (crData) {
          setProjectData((prevData) => ({
            ...prevData,
            crNo: crData.crNo,
            estimatedHours: crData.estimatedHours,
            approvedHours: crData.approvedHours,
            notApprovedHours: crData.notApprovedHours,
          }));
          setShowValidation((previousData) => ({
            ...previousData,
            crNoError: false,
            estimatedHoursError: false,
            approvedHoursError: false,
            notApprovedHoursError: false,
          }));
          fetchChangeRequestData(crData.crNo);
        }
      } catch (error) {
        console.error("Error fetching CR data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchChangeRequestData = async (crNo) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiEndPoints.getChangeRequestData}?CrNo=${crNo}`
        );

        const changeRequestData = response.data;
        setProjectData((prevData) => ({
          ...prevData,
          changeRequest: changeRequestData.changeRequest,
        }));
        setShowValidation((previousData) => ({
          ...previousData,
          changeRequestError: false,
        }));
      } catch (error) {
        console.error("Error fetching Change Request data:", error);
      } finally {
        setLoading(false);
      }
    };
    if (
      projectData.azureProjectName &&
      projectData.monthCycle &&
      projectData.year
    ) {
      fetchCRData();
    }
  }, [projectData.azureProjectName, projectData.monthCycle, projectData.year]);

  const clearFormFields = () => {
    setProjectData(initialProjectData);
    setShowValidation(false);
  };
  const clearFormFieldsOnAdd = () => {
    setProjectData({
      departmentName: projectData.departmentName,
      azureProjectName: "",
      monthCycle: projectData.monthCycle,
      year: new Date(2023, 0, 1),
      changeRequest: "",
      crNo: "",
      estimatedHours: "",
      approvedHours: "",
      notApprovedHours: "",
      approved: "",
      notApproved: "",
      comment: "",
    });

    setShowValidation((prev) => {
      return {
        ...prev,
        azureProjectNameError: null,
        approvedError: null,
        notApprovedError: null,
        commentError: null,
        changeRequestError: null,
        crNoError: null,
        estimatedHoursError: null,
        approvedHoursError: null,
        notApprovedHoursError: null,
      };
    });
  };

  const validationHandler = () => {
    const data = Object.values(showValidation).every((item) => item === false);
    const data1 = { ...projectData };
    const data2 = { ...showValidation };

    for (const key in data1) {
      if (data1[key] === "") {
        data2[`${key}Error`] = true;
      }
    }
    setShowValidation(data2);
    return data;
  };

  const handleSubmit = async () => {
    if (validationHandler() === false) {
      return false;
    }
    try {
      const employeeName = sessionStorage.getItem("employeeName");
      const response = await axios.post(apiEndPoints.addTrackingChangeRequest, {
        departmentId: projectData.departmentName.value,
        azureProjectId: projectData.azureProjectName.value,
        year: projectData.year.getFullYear(),
        monthCycle: projectData.monthCycle.value,
        changeRequest: projectData.changeRequest,
        crNo: projectData.crNo,
        estimatedHours: projectData.estimatedHours,
        approvedHours: projectData.approvedHours,
        notApprovedHours: projectData.notApprovedHours,
        approved: projectData.approved.value,
        notApproved: projectData.notApproved.value,
        comment: projectData.comment,
        username: employeeName,
      });
      clearFormFieldsOnAdd();
      // document.getElementById("exampleModalLong2").click();
      props.fetchTrackingChange();
      toast.success("Form submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const errorMessage = error.response.data.message;
        alert(`${errorMessage}`);
      } else {
        console.error("Error submission", error);
      }
    }
  };

  return (
    <form
      className="modal fade bd-example-modal-lg"
      id="exampleModalLong2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Tracking Change Request
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearFormFields();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.departmentNameError ? "invalid" : ""}
              label="Department Name"
              id="deptName"
              placeholder="Department Name"
              value={projectData.departmentName}
              onChange={(value, e) => {
                handleProjectData(value, "departmentName");
              }}
              options={masterData.departments}
              isSearchable={true}
            />
            <SelectList
              ErrorStyle={showValidation.azureProjectNameError ? "invalid" : ""}
              label="Project Name"
              id="Project"
              placeholder="Project Name"
              value={projectData.azureProjectName}
              onChange={(value, e) => {
                handleProjectData(value, "azureProjectName");
              }}
              options={projectList}
              isSearchable={true}
            />
            <DatePickerField
              id="projYear"
              label="Year"
              showYearPicker
              dateFormat="yyyy"
              placeholderText="Select Year"
              selected={projectData.year}
              onChange={(date) => {
                handleProjectData(date, "year");
              }}
            />
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.monthCycleError ? "invalid" : ""}
              label="Quarter"
              id="Quarter"
              placeholder="Quarter"
              options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
              defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
              value={projectData.monthCycle}
              onChange={(value) => handleProjectData(value, "monthCycle")}
            />
            <TextInputField
              label="Change Request"
              id="changeRequest"
              options={["Yes", "No"]}
              value={projectData.changeRequest}
              onChange={(e) =>
                handleProjectData(e.target.value, "changeRequest")
              }
              loading={loading}
            />
            <TextInputField
              label="CR No"
              id="crNo"
              value={projectData.crNo}
              onChange={(e) => handleProjectData(e.target.value, "crNo")}
              loading={loading}
            />
          </div>
          <div className="modal-body">
            <TextInputField
              label="CR Estimated Hours"
              id="crEstimatedHours"
              value={projectData.estimatedHours}
              onChange={(e) =>
                handleProjectData(e.target.value, "estimatedHours")
              }
              loading={loading}
            />
            <TextInputField
              label="Approved Hours"
              id="approvedHours"
              value={projectData.approvedHours}
              onChange={(e) =>
                handleProjectData(e.target.value, "approvedHours")
              }
              loading={loading}
            />
            <TextInputField
              label="Not Approved Hours"
              id="notApprovedHours"
              value={projectData.notApprovedHours}
              onChange={(e) =>
                handleProjectData(e.target.value, "notApprovedHours")
              }
              loading={loading}
            />
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.notApprovedError ? "invalid" : ""}
              label="Not Approved"
              id="notApproved"
              placeholder="Not Approved"
              options={["Yes", "No", "N/A"]}
              value={projectData.notApproved}
              onChange={(value, e) => handleProjectData(value, "notApproved")}
            />
            <SelectList
              ErrorStyle={showValidation.approvedError ? "invalid" : ""}
              label="Approved"
              id="approved"
              placeholder="Approved"
              options={["Yes", "No", "N/A"]}
              value={projectData.approved}
              onChange={(value, e) => handleProjectData(value, "approved")}
            />
            <TextArea
              ErrorStyle={showValidation.commentError ? "invalid" : ""}
              label="Comment"
              id="comment"
              rows={1}
              cols={20}
              value={projectData.comment}
              onChange={(e) => handleProjectData(e.target.value, "comment")}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-success btn-Add"
            >
              Add
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                clearFormFields();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormTrackingChange;
