import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import "./SideBar.css";

const SideBar = () => {
	return (
		<div className="sidebar">
			<div className="sidebar-content">
				<ul>
					<li>
						<NavLink to="/CustomerSatisfaction" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Customer Satisfaction
						</NavLink>
					</li>
					<li>
						<NavLink to="/CodeReview" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Code Review
						</NavLink>
					</li>
					<li>
						<NavLink to="/ProjectProcess" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Project Process Compliance
						</NavLink>
					</li>
					<li>
						<NavLink to="/ApplicationLifeCycle" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Application Life Cycle
						</NavLink>
					</li>
					<li>
						<NavLink to="/ProjectEffort" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Project Effort Variance
						</NavLink>
					</li>
					<li>
						<NavLink to="/ProjectPlan" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Project Plan
						</NavLink>
					</li>
					<li>
						<NavLink to="/TrackingChange" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Tracking Change Request
						</NavLink>
					</li>
					<li>
						<NavLink to="/AutomationSecurity" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Automation, Security & Performance
						</NavLink>
					</li>
					<li>
						<NavLink to="/QualityDevelopment" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Quality Of Development
						</NavLink>
					</li>
					<li>
						<NavLink to="/QualityTesting" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Quality Of Testing
						</NavLink>
					</li>
					<li>
						<NavLink to="/ArchitectureApproval" activeclassname="active">
							<FontAwesomeIcon icon={faFile} />
							Architecture Approval
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SideBar;
