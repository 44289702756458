import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import DatePickerField from "../../Components/DatePickerField";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiEndPoints } from "../../constants/apiEndPoints";

const UpdateCustomerSatisfaction = ({ record, onUpdate, onUpdateFunction }) => {
  const [editedRecord, setEditedRecord] = useState({
    ...record,
    year: new Date(record.year.toString()),
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showValidation, setShowValidation] = useState({
    monthCycleError: "",
    satisfactionLevelError: "",
    commentError: "",
  });

  const handleFieldChange = (fieldName, value) => {
    const updatedRecord = { ...editedRecord };
    if (fieldName === "dateformat") {
      updatedRecord.date = formatDateToYYYYMMDD(value);
    } else {
      updatedRecord[fieldName] = value;
    }

    const newValidationState = { ...showValidation };
    if (fieldName === "monthCycle") {
      newValidationState.monthCycleError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "satisfactionLevel") {
      newValidationState.satisfactionLevelError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "comment") {
      newValidationState.commentError = value ? "" : "This field is required.";
    }
    setShowValidation(newValidationState);
    setEditedRecord(updatedRecord);
  };

  const formatDateToYYYYMMDD = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleUpdate = async () => {
    const isFormValid = Object.values(showValidation).every(
      (error) => error === ""
    );
    if (!isFormValid) {
      return;
    }
    try {
      const employeeName = sessionStorage.getItem("employeeName");
      const response = await axios.put(
        apiEndPoints.updateCustomerSatisfactionRecord,
        {
          ...editedRecord,
          year: editedRecord?.year.getFullYear(),
          username: employeeName,
        }
      );
      if (response.status === 200) {
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Adjust as needed
        });
        onUpdateFunction();
        onUpdate();
      }
    } catch (error) {}
  };

  const handleOnChange = (date) => {
    setEditedRecord((state) => ({
      ...state,
      date: moment(date).format("YYYY-MM-DD"),
    }));
  };

  return (
    <>
      <Modal
        className={`modal fade bd-example-modal-lg ${
          isModalVisible ? "show" : ""
        }`}
        show={true}
        onHide={onUpdate}
        centered
        size="lg"
        style={{ display: isModalVisible ? "block" : "none" }}
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Update Customer Satisfaction</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form style={{ width: "100%" }}>
            <div className="row">
              <Form.Group
                className="form-group col-md-4"
                controlId="departmentName"
              >
                <Form.Label>Department Name:</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={editedRecord.departmentName}
                  onChange={(e) =>
                    handleFieldChange("departmentName", e.target.value)
                  }
                />
              </Form.Group>
              <Form.Group
                className="form-group col-md-4"
                controlId="azureProjectName"
              >
                <Form.Label>Project Name:</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={editedRecord.azureProjectName}
                  onChange={(e) =>
                    handleFieldChange("azureProjectName", e.target.value)
                  }
                />
              </Form.Group>
              <DatePickerField
                disabled
                className="form-control"
                label="Year:"
                type="year"
                showYearPicker
                selected={editedRecord?.year ?? new Date()}
                dateFormat="yyyy"
                onChange={(year) => handleFieldChange("year", year)}
              />
            </div>
            <div className="row">
              <Form.Group
                className="form-group col-md-4"
                controlId="monthCycle"
              >
                <Form.Label>Quarter:</Form.Label>
                <Form.Control
                  // disabled
                  as="select"
                  value={editedRecord.monthCycle}
                  className={showValidation.monthCycleError ? "invalid" : ""}
                  onChange={(e) =>
                    handleFieldChange("monthCycle", e.target.value)
                  }
                >
                  <option value="Jan-Mar">Jan-Mar</option>
                  <option value="Apr-Jun">Apr-Jun</option>
                  <option value="July-Sep">July-Sep</option>
                  <option value="Oct-Dec">Oct-Dec</option>
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="form-group col-md-4"
                controlId="satisfactionLevel"
              >
                <Form.Label>Satisfaction Level:</Form.Label>
                <Form.Control
                  as="select"
                  value={editedRecord.satisfactionLevel}
                  className={
                    showValidation.satisfactionLevelError ? "invalid" : ""
                  }
                  onChange={(e) =>
                    handleFieldChange("satisfactionLevel", e.target.value)
                  }
                >
                  <option value="Good">Good</option>
                  <option value="Average">Average</option>
                  <option value="Need Improvement">Need Improvement</option>
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="form-group col-md-4"
                controlId="referenceMail"
              >
                <Form.Label>Reference Mail:</Form.Label>
                <Form.Control
                  as="select"
                  value={editedRecord.referenceMail}
                  onChange={(e) =>
                    handleFieldChange("referenceMail", e.target.value)
                  }
                >
                  <option value="Yes">Select an option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="N/A">N/A</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="row">
              <DatePickerField
                className="form-control"
                label="Date:"
                onChange={(date) => handleOnChange(date)}
                value={editedRecord.date}
                dateFormat="yyyy-mm-dd"
              />
              <Form.Group className="form-group col-md-4" controlId="comment">
                <Form.Label>Comment:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  value={editedRecord.comment}
                  className={showValidation.commentError ? "invalid" : ""}
                  onChange={(e) => handleFieldChange("comment", e.target.value)}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
          <Button variant="secondary" onClick={onUpdate}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateCustomerSatisfaction;
