function TextArea(props) {
  const { label, value, rows, cols, onChange, ErrorStyle, design } = props;

  return (
    <div className={`form-group col-md-4 ${design}`}>
      <label>{label} </label>
      <textarea
        className={`form-control ${ErrorStyle}`}
        value={value}
        onChange={onChange}
        rows={rows || 5}
        cols={cols || 30}
      />
    </div>
  );
}

export default TextArea;
