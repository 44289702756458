import React, { useState, useEffect } from "react";
import SelectList from "../../Components/Select";
import { callAPI } from "../../utils/callAPI";
import axios from "axios";
import { apiEndPoints } from "../../constants/apiEndPoints";
import DatePickerField from "../../Components/DatePickerField";
import SelectInputField from "../../Components/SelectInputField";
import "../Pages.css";
import FormApplicationLifeCycle from "./FormApplicationLifeCycle";
import TableApplicationLifeCycle from "./TableApplicationLifeCycle";
import { exportDataToExcel } from "../../utils/excelExportUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { Spin } from "antd";

const ApplicationLifeCycle = (props) => {
  const [projectData, setProjectData] = useState({
    pageNumber: 1,
    departmentName: "",
    project: "",
    quarter: "",
    year: "",
  });
  const [applicationLifeCycle, setApplicationLifeCycle] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [masterData, setMasterData] = useState({
    departments: [],
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  useEffect(() => {
    const getProjects = async () => {
      let projectList = [];
      if (projectData.departmentName) {
        const departmentName = projectData.departmentName.label;
        try {
          const resp = await callAPI(
            "GET",
            apiEndPoints.getProjName + "?departmentName=" + departmentName
          );
          projectList = resp.data.data;
          projectList.forEach((item) => {
            item.value = item.azureProjectId;
            item.label = item.azureProjectName;
            delete item.azureProjectId;
            delete item.project;
            delete item.department;
          });
        } catch (err) {
          console.log("Error in fetching Project Names: ", err);
        }
      }
      setProjectList(projectList);
    };
    getProjects();
  }, [projectData.departmentName]);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
        ]);
        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;
          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });
          setMasterData({
            ...{ departments },
          });
        }
      } catch (error) {}
    };
    getDepartments();
  }, []);

  const handleProjectData = (evt, key, val) => {
    const value = evt && evt.target ? evt.target.value : val;
    if (key === "departmentName" && projectData.project) {
      setProjectData({
        ...projectData,
        project: "",
        ...{ [key]: value },
      });
    } else {
      setProjectData({
        ...projectData,
        [key]: value,
      });
    }
  };

  const handleSearch = async () => {
    const searchParams = { ...projectData };
    for (const key in searchParams) {
      if (searchParams[key] === "" || searchParams[key] === undefined) {
        delete searchParams[key];
      }
    }
    if (searchParams.departmentName) {
      searchParams.departmentName = searchParams.departmentName.label;
    } else {
      delete searchParams.departmentName;
    }
    if (searchParams.project) {
      searchParams.project = searchParams.project.label;
    } else {
      delete searchParams.project;
    }
    if (searchParams.year !== "") {
      const yearValue = new Date(searchParams.year).getFullYear();
      if (!isNaN(yearValue)) {
        searchParams.year = yearValue.toString();
      } else {
        delete searchParams.year;
      }
    } else {
      delete searchParams.year;
    }
    if (!searchParams.quarter) {
      delete searchParams.quarter;
    }
    try {
      setLoadingSearch(true);
      const response = await axios.get(apiEndPoints.getALMTableData, {
        params: {
          ...searchParams,
          pageNumber: 1,
          pageSize: pagination.pageSize,
        },
      });

      const data = response.data.data;
      setTimeout(() => {
        setApplicationLifeCycle(data);
        setRecordCount(response.data.count);
        setLoadingSearch(false);
      }, 200);
    } catch (error) {
      console.error("Error fetching Table Data: ", error);
    }
  };

  const fetchApplicationLifeCycle = async (page, pageSize) => {
    const { searchParams } = props; //destructuring
    let urlParams = "";
    for (let param in searchParams) {
      if (urlParams !== "") urlParams += "&";
      urlParams += `${param}=${searchParams[param]}`;
    }
    if (urlParams !== "") urlParams = "?" + urlParams;
    try {
      const response = await fetch(apiEndPoints.getALMTableData + urlParams, {
        params: {
          pageNumber: page,
          pageSize: pageSize,
        },
      });

      const data = await response.json();
      setApplicationLifeCycle(data.data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize,
      }));
      setRecordCount(data.count);
    } catch (error) {
      console.error("Error fetching Table Data: ", error);
    }
  };

  const handleExportToExcel = async () => {
    if (
      !projectData.departmentName ||
      !projectData.quarter ||
      !projectData.year
    ) {
      alert(
        "Please fill in Department Name, Quarter, and Year before exporting."
      );
      return;
    }
    setLoadingExport(true);

    const exportParams = {
      departmentName: projectData.departmentName
        ? projectData.departmentName.label
        : "",
      quarter: projectData.quarter || "",
      year:
        projectData.year instanceof Date ? projectData.year.getFullYear() : "",
    };
    try {
      await exportDataToExcel(exportParams, apiEndPoints.downloadALM);
    } catch (error) {
      console.error("Error during export:", error);
    } finally {
      setLoadingExport(false);
    }
  };

  const deleteData = async (id) => {
    try {
      await axios.delete(`${apiEndPoints.deleteALMRecord}${id}`);
      toast.error(" Deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      handleSearch();
    } catch (error) {}
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content ALM-Module">
        <div className="page-header">
          <h1>Application Life Cycle</h1>
        </div>
        <div className="page-container">
          <div className="form-row">
            <SelectList
              label="Department Name"
              id="deptName"
              placeholder="Department Name"
              value={projectData.departmentName}
              onChange={(value, e) => {
                handleProjectData(e, "departmentName", value);
              }}
              options={masterData.departments}
              isSearchable={true}
            />
            <SelectList
              label="Project Name"
              id="Project"
              placeholder="Project Name"
              value={projectData.project}
              onChange={(value, e) => {
                handleProjectData(e, "project", value);
              }}
              options={projectList}
              isSearchable={true}
            />
          </div>
          <div className="form-row">
            <DatePickerField
              id="projYear"
              label="Select Year"
              showYearPicker
              dateFormat="yyyy"
              placeholderText="Select Year"
              selected={projectData.year}
              onChange={(date, evt) => {
                handleProjectData(evt, "year", date);
              }}
            />
            <SelectInputField
              label="Select Quarter"
              id="Quarter"
              options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
              value={projectData.quarter}
              onChange={(e) => handleProjectData(e, "quarter")}
            />
          </div>
          <div className="action-button">
            <div className="left-action-button">
              <div>
                <button
                  type="button"
                  className="btn btn-success btn-Add"
                  data-toggle="modal"
                  data-target="#exampleModalLong2"
                >
                  Add
                </button>
                <FormApplicationLifeCycle
                  fetchApplicationLifeCycle={fetchApplicationLifeCycle}
                  applicationLifeCycle={applicationLifeCycle}
                />
                &nbsp;
              </div>
              <div>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    loadingSearch ? "loading" : ""
                  }`}
                  onClick={handleSearch}
                  disabled={loadingSearch}
                >
                  {loadingSearch ? (
                    <Spin style={{ marginRight: 8 }} />
                  ) : (
                    <>Search</>
                  )}
                </button>
              </div>
              &nbsp;
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setProjectData({
                      pageNumber: 1,
                      departmentName: "",
                      project: "",
                      quarter: "",
                      year: "",
                    });
                    fetchApplicationLifeCycle();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="right-action-button">
              <div className="countNumber border mt-20px">
                <label>
                  Total Projects :- <span className="ml-1">{recordCount}</span>{" "}
                </label>
              </div>
              <div className="export">
                <button
                  type="button"
                  // className="btn-export"
                  className={`btn-export ${loadingExport ? "loading" : ""}`}
                  onClick={handleExportToExcel}
                  disabled={loadingExport}
                >
                  {loadingExport ? (
                    <Spin style={{ marginRight: 8 }} />
                  ) : (
                    <>
                      Export
                      <FontAwesomeIcon icon={faFileExport} />{" "}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Table ALM-Table">
          <TableApplicationLifeCycle
            fetchApplicationLifeCycle={fetchApplicationLifeCycle}
            applicationLifeCycle={applicationLifeCycle}
            onDeleteFunction={deleteData}
            onUpdateFunction={handleSearch}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicationLifeCycle;
