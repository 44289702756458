import axios from "axios";

export const callAPI = async (reqType, apiURL, data, config) => {
  let respData = null;
  if (reqType === "GET") {
    respData = await getDataFromApi(apiURL, config);
    return respData;
  } else {
    respData = await postDataToApi(apiURL, data, config);
    return respData;
  }
};

const getDataFromApi = async (apiURL, config) => {
  try {
    const apiData = await axios.get(apiURL, config);
    return apiData;
  } catch (error) {
    throw error;
  }
};

const postDataToApi = async (apiURL, data, config) => {
  const postConfig = {
    ...{
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    ...config,
  };
  try {
    const apiData = await axios({
      method: "POST",
      url: apiURL,
      data: JSON.stringify(data),
      postConfig,
    });
    return apiData;
  } catch (error) {
    throw error;
  }
};
