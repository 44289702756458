import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Table, Spin, Button } from "antd";
import { EyeOutlined, EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import { apiEndPoints } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import DatePickerField from "../../Components/DatePickerField";
import SelectInputField from "../../Components/SelectInputField";
import TextInputField from "../../Components/TextInputField";
import UpdateApplicationLifeCycle from "./UpdateApplicationLifeCycle";
import DeleteCmfModal from "../../Components/DeleteCmfModal/DeleteCmfModal";

const TableApplicationLifeCycle = (props) => {
	const [editingRecord, setEditingRecord] = useState(null);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
	});
	const [deleteModalData, setDeleteModalData] = useState({
		show: false,
		id: "",
	});
	// const [isModalVisible, setIsModalVisible] = useState(false);
	const [isEyeIconModalVisible, setIsEyeIconModalVisible] = useState(false);
	const [isEditIconModalVisible, setIsEditIconModalVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [modalData, setModalData] = useState(null);
	const [editableRows, setEditableRows] = useState({});
	const [viewProjectData, setViewProjectData] = useState({
		departmentName: "",
		azureProjectName: "",
		year: "",
		quarter: "",
	});

	const [statusError, setStatusError] = useState(false);

	const handleEyeIconClick = async (record) => {
		try {
			setLoading(true);
			const response = await axios.get(
				`${apiEndPoints.getApplicationData}/${record.id}`
			);
			setEditingRecord(record);
			setModalData(response.data.datalist);
			setIsEyeIconModalVisible(true);
			setIsEditIconModalVisible(false);
			setLoading(false);
			setViewProjectData({
				departmentName: response.data.departmentName,
				azureProjectName: response.data.azureProjectName,
				year: response.data.year,
				quarter: response.data.quarter,
			});
			setEditableRows({});
		} catch (error) {
			console.error("Error fetching data: ", error);
			setLoading(false);
		}
	};

	const onEdit = (record) => {
		setEditingRecord(record);
		setIsEditIconModalVisible(true);
		setIsEyeIconModalVisible(false);
	};

	useEffect(() => {
		setLoading(true);
		props.fetchApplicationLifeCycle(pagination.current, pagination.pageSize);
		setLoading(false);
	}, [props.searchParams]);

	useEffect(() => {
		if (isEyeIconModalVisible || isEditIconModalVisible) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isEyeIconModalVisible, isEditIconModalVisible]);

	const toggleEditMode = (record) => {
		setEditableRows((prevEditableRows) => ({
			...prevEditableRows,
			[record.key]: !prevEditableRows[record.key],
		}));
	};

	const handleEditInputChange = (value, key, field) => {
		const newData = [...modalData];
		const [phaseId, activityId] = key.split("_");
		const phaseIndex = newData.findIndex(
			(item) => item.phaseId === Number(phaseId)
		);
		if (phaseIndex !== -1) {
			const activityIndex = newData[phaseIndex].activities.findIndex(
				(activity) => activity.activityId === Number(activityId)
			);
			if (activityIndex !== -1) {
				newData[phaseIndex].activities[activityIndex][field] = value;
				setModalData([...newData]);
			} else {
				console.error("Activity Not Found: ", `${phaseId}_${activityId}`);
			}
		} else {
			console.error("Phase Not Found: ", phaseId);
		}
	};

	const handleDateView = (d) => {
		if (typeof d == "object") {
			return moment(d).format("yyyy-MM-DD");
		} else {
			return d;
		}
	};

	const handleDate = (d) => {
		if (typeof d == "object") {
			return moment(d).format();
		} else {
			return d;
		}
	};

	const handleSaveChanges = async (record) => {
		const isStatusEmpty = modalData.some((phase) =>
			phase.activities.some(
				(activity) =>
					activity.status === undefined || activity.status.trim() === ""
			)
		);
		if (isStatusEmpty) {
			toast.error("Status cannot be empty", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
			});
			return;
		}
		try {
			const employeeName = sessionStorage.getItem("employeeName");
			let transformedData = transformDataForServer(modalData, record);
			transformedData = {
				...transformedData,
				startDate: handleDate(transformedData?.startDate),
				endDate: handleDate(transformedData?.endDate),
				username: employeeName, // Use the latest employeeName here
			};
			const response = await axios.post(
				apiEndPoints.updateALMRecord,
				transformedData
			);
			// console.log(response, "test----");
			if (response.status === 200) {
				toast.success("Data has been updated", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 2000,
				});
				// console.log(JSON.stringify(record), "hey there");
				setModalData((prevData) => {
					const newData = [...prevData];
					const activityToUpdate = newData
						.flatMap((phase) => phase.activities)
						.find((activity) => activity.id === record.id);
					if (activityToUpdate) {
						activityToUpdate.username = employeeName;
					}
					return newData;
				});
			}
		} catch (error) {
			console.error("ERROR: ", error);
		}
		toggleEditMode(record);
	};

	const transformDataForServer = (modalData, editingRecord) => {
		if (!modalData || !modalData.length || !editingRecord) {
			return null;
		}

		const editedActivity = modalData
			.flatMap((phase) => phase.activities)
			.find((activity) => activity.id === editingRecord.id);
		return editedActivity || null;
	};

	const transformedData = transformDataForServer(modalData, editingRecord);

	const handleTableChange = (pagination) => {
		setPagination(pagination);
	};

	const onDelete = async (id) => {
		props.onDeleteFunction(id);
	};

	const columns = [
		{
			className: "table_heading",
			title: "Department",
			dataIndex: "departmentName",
			width: 120,
		},
		{
			title: "Project",
			dataIndex: "azureProjectName",
			width: 120,
		},
		{
			title: "Year",
			dataIndex: "year",
			width: 70,
		},
		{
			title: "Quarter",
			dataIndex: "quarter",
			width: 70,
		},
		{
			title: "Action",
			width: 80,
			render: (data, record) => (
				<span className="Action-button">
					<EyeOutlined
						twoToneColor="#00ff00"
						onClick={() => handleEyeIconClick(record)}
					/>
					&nbsp;&nbsp;
					<EditTwoTone twoToneColor="#00ff00" onClick={() => onEdit(record)} />
					&nbsp;&nbsp;
					<DeleteTwoTone
						twoToneColor="#ff0000"
						onClick={() => setDeleteModalData({ show: true, id: data.id })}
					/>
				</span>
			),
		},
	];

	const modalColumns = [
		{ title: "Phase", dataIndex: "phaseName", key: "phaseName", width: 90 },
		{
			title: "Activities",
			dataIndex: "activityName",
			key: "activityName",
			width: 90,
		},
		{
			title: "Start Date",
			dataIndex: "startDate",
			key: "startDate",
			width: 80,
			render: (text, record) =>
				editableRows[record.key] ? (
					<DatePickerField
						design="design"
						selected={record.startDate}
						value={text ? handleDateView(text) : null}
						onChange={(date) =>
							handleEditInputChange(
								date,
								`${record.phaseId}_${record.activityId}`,
								"startDate"
							)
						}
					/>
				) : (
					handleDateView(text)
				),
		},
		{
			title: "End Date",
			dataIndex: "endDate",
			key: "endDate",
			width: 80,
			render: (text, record) =>
				editableRows[record.key] ? (
					<DatePickerField
						design="design"
						selected={record.endDate}
						value={text ? handleDateView(text) : null}
						onChange={(date) =>
							handleEditInputChange(
								date,
								`${record.phaseId}_${record.activityId}`,
								"endDate"
							)
						}
					/>
				) : (
					handleDateView(text)
				),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 80,
			render: (text, record) =>
				editableRows[record.key] ? (
					<SelectInputField
						id={`${record.phaseId}_${record.activityId}`}
						value={text}
						onChange={(e) =>
							handleEditInputChange(
								e.target.value,
								`${record.phaseId}_${record.activityId}`,
								"status"
							)
						}
						options={[
							"Full Compliance",
							"Partial Compliance",
							"Non Compliance",
							"N/A",
						]}
						design={statusError ? "design error" : "design"}
					/>
				) : (
					text
				),
		},
		{
			title: "Comment",
			dataIndex: "comment",
			key: "comment",
			width: 90,
			render: (text, record) => (
				<div
					style={
						editableRows[record.key]
							? {}
							: { maxHeight: "50px", overflowY: "auto" }
					}>
					{editableRows[record.key] ? (
						<TextInputField
							id={`${record.phaseId}_${record.activityId}`}
							value={text}
							ErrorStyle="design"
							onChange={(e) =>
								handleEditInputChange(
									e.target.value,
									`${record.phaseId}_${record.activityId}`,
									"comment"
								)
							}
						/>
					) : (
						text
					)}
				</div>
			),
		},
		{
			title: "Submitted By",
			dataIndex: "username",
			width: 90,
		},
		{
			title: "Action",
			width: 100,
			render: (text, record) => (
				<span>
					{editableRows[record.key] ? (
						<>
							<Button type="link" onClick={() => handleSaveChanges(record)}>
								Save
							</Button>
							&nbsp;&nbsp;
						</>
					) : (
						<Button type="link" onClick={() => toggleEditMode(record)}>
							Edit
						</Button>
					)}
				</span>
			),
		},
	];

	return (
		<div className="table_main view-ALM">
			<div className="table_container">
				<Spin spinning={loading}>
					<DeleteCmfModal
						onClick={() => onDelete(deleteModalData?.id)}
						visible={deleteModalData?.show}
						onCancel={() => setDeleteModalData({ show: false, id: "" })}
					/>
					<Table
						className="table_main table-striped"
						columns={columns.map((column) => ({ ...column, className: "" }))}
						dataSource={props.applicationLifeCycle}
						pagination={pagination}
						onChange={handleTableChange}
						scroll={{
							x: 1000,
						}}
					/>
				</Spin>
				{isEyeIconModalVisible && (
					<div
						className={`modal fade bd-example-modal-lg modal-overlay  aria-hidden="true" ${
							isEyeIconModalVisible ? "show" : ""
						}`}
						tabIndex="-1"
						role="dialog"
						style={{ display: isEyeIconModalVisible ? "block" : "none" }}
						data-backdrop="static"
						data-keyboard="false">
						<div className="modal-dialog modal-lg view-modal">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Application Life Cycle</h5>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={() => {
											setIsEyeIconModalVisible(false);
											setEditingRecord(null);
											setModalData(null);
										}}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body d-block view-table update_ALM">
									<div className="view-top-content row">
										<Form.Group
											className="form-group col-md-3 col-sm-6"
											controlId="departmentName">
											<Form.Label>Department Name:</Form.Label>
											<Form.Control
												disabled
												type="text"
												value={viewProjectData.departmentName}
											/>
										</Form.Group>
										<Form.Group
											className="form-group col-md-3 col-sm-6"
											controlId="azureProjectName">
											<Form.Label>Project Name:</Form.Label>
											<Form.Control
												disabled
												type="text"
												value={viewProjectData.azureProjectName}
											/>
										</Form.Group>
										<Form.Group
											className="form-group col-md-3 col-sm-6"
											controlId="year">
											<Form.Label>Year:</Form.Label>
											<Form.Control
												disabled
												type="year"
												value={viewProjectData.year}
											/>
										</Form.Group>
										<Form.Group
											className="form-group col-md-3 col-sm-6"
											controlId="quarter">
											<Form.Label>Quarter:</Form.Label>
											<Form.Control
												disabled
												type="text"
												value={viewProjectData.quarter}></Form.Control>
										</Form.Group>
									</div>
									{/* <Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="teamLead">
												<Form.Label>Team Lead:</Form.Label>
												<Form.Control
													disabled
													type="text"
													value={viewProjectData.teamLead}></Form.Control>
											</Form.Group>
											<Form.Group
												className="form-group col-md-4 col-sm-6"
												controlId="projectPhase">
												<Form.Label>Project Phase:</Form.Label>
												<Form.Control
													disabled
													type="text"
													value={viewProjectData.projectPhase}></Form.Control>
											</Form.Group> */}
									{Array.isArray(modalData) && modalData.length > 0 && (
										<Table
											className="text-wrap  "
											columns={modalColumns}
											scroll={{
												// y: 500,
												x: 1000,
											}}
											dataSource={modalData.flatMap((phase) =>
												phase.activities.map((activity) => ({
													...phase,
													...activity,
													key: activity.id,
												}))
											)}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				{isEditIconModalVisible && (
					<UpdateApplicationLifeCycle
						record={editingRecord}
						onUpdate={() => {
							setIsEditIconModalVisible(false);
							setEditingRecord(null);
						}}
						onUpdateFunction={props.onUpdateFunction}
					/>
				)}
			</div>
		</div>
	);
};

export default TableApplicationLifeCycle;
