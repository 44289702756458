import React, { useEffect, useState } from "react";
import axios from "axios";
import { callAPI } from "../../utils/callAPI";
import { apiEndPoints } from "../../constants/apiEndPoints";
import SelectList from "../../Components/Select";
import DatePickerField from "../../Components/DatePickerField";
import TextInputField from "../../Components/TextInputField";
import "../Pages.css";
import TextArea from "../../Components/TextArea";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialProjectData = {
  departmentName: "",
  azureProjectName: "",
  // year: new Date(),
  // monthCycle: "",
  monthCycle: { value: "Oct-Dec", label: "Oct-Dec" },
  year: new Date(2023, 0, 1),
  review: "",
  codeReusability: "",
  codingStandard: "",
  devOps: { value: "Yes", label: "Yes" },
  devOpsTool: { value: "DevOps", label: "DevOps" },
  reviewDate: "",
  reviewer: "",
  comment: "",
};

const FormCodeReview = (props) => {
  const [projectData, setProjectData] = useState(initialProjectData);
  const [showValidation, setShowValidation] = useState({
    departmentNameError: null,
    azureProjectNameError: null,
    // monthCycleError: null,
    reviewError: null,
    codeReusabilityError: null,
    codingStandardError: null,
    devOpsError: null,
    devOpsToolError: null,
    reviewerError: null,
    commentError: null,
  });
  const [masterData, setMasterData] = useState({
    departments: [],
  });
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
        ]);
        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;
          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });
          setMasterData({
            ...{ departments },
          });
        }
      } catch (error) {}
    };
    getDepartments();
  }, []);

  useEffect(() => {
    const getProjects = async () => {
      let projectList = [];
      if (projectData.departmentName) {
        const departmentName = projectData.departmentName.label;
        try {
          const resp = await callAPI(
            "GET",
            apiEndPoints.getProjName + "?departmentName=" + departmentName
          );
          projectList = resp.data.data;
          projectList.forEach((item) => {
            item.value = item.azureProjectId;
            item.label = item.azureProjectName;
            delete item.azureProjectId;
            delete item.project;
            delete item.department;
          });
        } catch (err) {
          console.log("Error in fetching Project Names: ", err);
        }
      }
      setProjectList(projectList);
    };
    getProjects();
  }, [projectData.departmentName]);

  useEffect(() => {
    const getBugCount = async () => {
      const { azureProjectName, monthCycle, year } = projectData;
      try {
        setLoading(true);
        const bugCountResponse = await axios.post(
          `${apiEndPoints.getBugCountCR}?AzureProjectId=${
            azureProjectName.value
          }&quarter=${monthCycle.value}&year=${year.getFullYear()}`
        );
        const bugDetails = bugCountResponse.data;
        if (bugDetails) {
          setProjectData((prevData) => ({
            ...prevData,
            numberOfBug: bugDetails.numberOfBug,
          }));
          setProjectData((prevData) => ({
            ...prevData,
            comment: `Bug Count: ${bugDetails.numberOfBug}`,
          }));

          let codingStandardMessage = "";
          const bugCount = bugDetails.numberOfBug;

          if (bugCount === 0) {
            codingStandardMessage = "N/A";
          } else if (bugCount >= 1 && bugCount <= 50) {
            codingStandardMessage = "Good";
          } else if (bugCount > 50 && bugCount <= 100) {
            codingStandardMessage = "Average";
          } else {
            codingStandardMessage = "Needs Improvement";
          }
          setProjectData((prevData) => ({
            ...prevData,
            codingStandard: codingStandardMessage,
          }));

          setShowValidation((previousData) => ({
            ...previousData,
            codingStandardError: false,
            commentError: false,
          }));
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (
      projectData.azureProjectName &&
      projectData.monthCycle &&
      projectData.year
    ) {
      getBugCount();
    }
  }, [projectData.azureProjectName, projectData.monthCycle, projectData.year]);

  const handleProjectData = (val, key) => {
    setProjectData((previousData) => {
      return {
        ...previousData,
        [key]: val,
      };
    });
    setShowValidation((previousData) => {
      return {
        ...previousData,
        [`${key}Error`]: val ? false : true,
      };
    });
  };

  const clearFormFields = () => {
    setProjectData(initialProjectData);
    setShowValidation(false);
  };

  const clearFormFieldsOnAdd = () => {
    setProjectData({
      departmentName: projectData.departmentName,
      azureProjectName: "",
      monthCycle: projectData.monthCycle,
      year: new Date(2023, 0, 1),
      review: "",
      codeReusability: "",
      codingStandard: "",
      devOps: projectData.devOps,
      devOpsTool: projectData.devOpsTool,
      reviewDate: "",
      reviewer: "",
      comment: "",
    });
    setShowValidation((prev) => {
      return {
        ...prev,
        azureProjectNameError: null,
        reviewError: null,
        codeReusabilityError: null,
        codingStandardError: null,
        devOpsError: null,
        devOpsToolError: null,
        reviewerError: null,
        commentError: null,
      };
    });
  };

  const validationHandler = () => {
    const data1 = { ...projectData };
    const data2 = { ...showValidation };

    let hasErrors = false;

    for (const key in data1) {
      if (key !== "reviewDate" && data1[key] === "") {
        data2[`${key}Error`] = true;
        hasErrors = true;
      }
    }
    setShowValidation(data2);
    return !hasErrors;
  };

  const handleSubmit = async () => {
    if (validationHandler() === false) {
      return false;
    }
    try {
      const employeeName = sessionStorage.getItem("employeeName");
      const response = await axios.post(apiEndPoints.addCodeReviewRecord, {
        departmentId: projectData.departmentName.value,
        azureProjectId: projectData.azureProjectName.value,
        monthCycle: projectData.monthCycle.value,
        year: projectData.year.getFullYear(),
        review: projectData.review.value,
        codeReusability: projectData.codeReusability.value,
        codingStandard: projectData.codingStandard,
        devOps: projectData.devOps.value,
        devOpsTool: projectData.devOpsTool.value,
        reviewDate: projectData.reviewDate,
        reviewer: projectData.reviewer,
        comment: projectData.comment,
        username: employeeName,
      });
      // clearFormFields();
      clearFormFieldsOnAdd();
      // document.getElementById("exampleModalLong2").click();
      props.fetchCodeReview();
      toast.success("Form submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const errorMessage = error.response.data.message;
        alert(`${errorMessage}`);
      } else {
        console.error("Error submission", error);
      }
    }
  };

  return (
    <form
      className="modal fade bd-example-modal-lg"
      id="exampleModalLong2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Code Review
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearFormFields();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.departmentNameError ? "invalid" : ""}
              label="Department Name"
              id="deptName"
              placeholder="Department Name"
              value={projectData.departmentName}
              onChange={(value) => {
                handleProjectData(value, "departmentName");
              }}
              options={masterData.departments}
              isSearchable={true}
            />
            <SelectList
              ErrorStyle={showValidation.azureProjectNameError ? "invalid" : ""}
              label="Project Name"
              id="Project"
              placeholder="Project Name"
              value={projectData.azureProjectName}
              onChange={(value) => {
                handleProjectData(value, "azureProjectName");
              }}
              options={projectList}
              isSearchable={true}
            />
            <DatePickerField
              id="Year"
              label=" Year"
              showYearPicker
              dateFormat="yyyy"
              placeholderText="Select Year"
              selected={projectData.year}
              onChange={(date) => {
                handleProjectData(date, "year");
              }}
            />
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.monthCycleError ? "invalid" : ""}
              label="Quarter"
              id="Quarter"
              placeholder="Quarter"
              options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
              defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
              value={projectData.monthCycle}
              onChange={(value) => handleProjectData(value, "monthCycle")}
            />
            <SelectList
              ErrorStyle={showValidation.reviewError ? "invalid" : ""}
              label="Code Review"
              id="review"
              placeholder="Code Review"
              options={["Yes", "No", "N/A"]}
              value={projectData.review}
              onChange={(value) => handleProjectData(value, "review")}
            />
            <SelectList
              ErrorStyle={showValidation.codeReusabilityError ? "invalid" : ""}
              label="Code Reusability"
              id="codeReusability"
              placeholder="Code Reusability"
              options={["Yes", "No", "N/A"]}
              value={projectData.codeReusability}
              onChange={(value) => handleProjectData(value, "codeReusability")}
            />
          </div>
          <div className="modal-body">
            <TextInputField
              ErrorStyle={showValidation.codingStandardError ? "invalid" : ""}
              label="Coding Standard"
              id="codingStandard"
              loading={loading}
              value={projectData.codingStandard}
              onChange={(e) =>
                handleProjectData(e.target.value, "codingStandard")
              }
            />
            <SelectList
              ErrorStyle={showValidation.devOpsError ? "invalid" : ""}
              label="DevOps"
              id="devOps"
              placeholder="DevOps"
              options={["Yes", "No", "N/A"]}
              value={projectData.devOps}
              defaultValue={{ value: "Yes", label: "Yes" }}
              onChange={(value) => handleProjectData(value, "devOps")}
            />
            <SelectList
              ErrorStyle={showValidation.devOpsToolError ? "invalid" : ""}
              label="DevOps Tool"
              id="devOpsTool"
              placeholder="DevOps Tool"
              options={["DevOps", "GIT", "SVN", "Other", "N/A"]}
              value={projectData.devOpsTool}
              defaultValue={{ value: "DevOps", label: "DevOps" }}
              onChange={(value) => handleProjectData(value, "devOpsTool")}
            />
          </div>
          <div className="modal-body">
            <DatePickerField
              id="reviewDate"
              label="Review Date"
              placeholderText="Select Date"
              dateFormat="yyyy-MM-dd"
              selected={projectData.reviewDate}
              onChange={(date) => {
                handleProjectData(date, "reviewDate");
              }}
            />
            <TextInputField
              ErrorStyle={showValidation.reviewerError ? "invalid" : ""}
              label="Reviewer"
              id="reviewer"
              placeholder="Enter Reviewer Name"
              value={projectData.reviewer}
              onChange={(e) => handleProjectData(e.target.value, "reviewer")}
            />
            <TextInputField
              ErrorStyle={showValidation.commentError ? "invalid" : ""}
              label="Comment"
              id="comment"
              value={projectData.comment}
              loading={loading}
              onChange={(e) => handleProjectData(e.target.value, "comment")}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-success btn-Add"
            >
              Add
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                clearFormFields();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormCodeReview;
