import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { apiEndPoints } from "../../constants/apiEndPoints";
import DatePickerField from "../../Components/DatePickerField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateQualityOfDevelopment = ({ record, onUpdate, onUpdateFunction }) => {
  const [editedRecord, setEditedRecord] = useState({
    ...record,
    year: new Date(record.year.toString()),
  });

  const [showValidation, setShowValidation] = useState({
    monthCycleError: "",
    securityError: "",
    performanceError: "",
    bugsPerHourError: "",
    defectDensityError: "",
    reviewerCommentError: "",
  });

  const handleFieldChange = (fieldName, value) => {
    const updatedRecord = { ...editedRecord };
    if (fieldName === "dateformat") {
      updatedRecord.date = formatDateToYYYYMMDD(value);
    } else {
      updatedRecord[fieldName] = value;
    }

    const newValidationState = { ...showValidation };

    if (fieldName === "monthCycle") {
      newValidationState.monthCycleError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "security") {
      newValidationState.securityError = value ? "" : "This field is required.";
    } else if (fieldName === "performance") {
      newValidationState.performanceError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "bugsPerHour") {
      newValidationState.bugsPerHourError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "defectDensity") {
      newValidationState.defectDensityError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "reviewerComment") {
      newValidationState.reviewerCommentError = value
        ? ""
        : "This field is required.";
    }
    setShowValidation(newValidationState);
    setEditedRecord(updatedRecord);
  };

  const formatDateToYYYYMMDD = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleUpdate = async () => {
    const isFormValid = Object.values(showValidation).every(
      (error) => error === ""
    );
    if (!isFormValid) {
      return;
    }
    try {
      const employeeName = sessionStorage.getItem("employeeName");
      const response = await axios.put(
        apiEndPoints.updateQualityOfDevelopmentRecord,
        {
          ...editedRecord,
          year: editedRecord?.year.getFullYear(),
          username: employeeName,
        }
      );
      if (response.status === 200) {
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        onUpdateFunction();
        onUpdate();
      }
    } catch (error) {}
  };

  return (
    <Modal
      className="modal fade bd-example-modal-lg"
      show={true}
      onHide={onUpdate}
      centered
      size="lg"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title>Update Quality Of Development</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Form style={{ width: "100%" }}>
          <div className="row">
            <Form.Group
              className="form-group col-md-4"
              controlId="departmentName"
            >
              <Form.Label>Department Name:</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={editedRecord.departmentName}
                onChange={(e) =>
                  handleFieldChange("departmentName", e.target.value)
                }
              />
            </Form.Group>
            <Form.Group
              className="form-group col-md-4"
              controlId="azureProjectName"
            >
              <Form.Label>Project Name:</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={editedRecord.azureProjectName}
                onChange={(e) =>
                  handleFieldChange("azureProjectName", e.target.value)
                }
              />
            </Form.Group>
            <DatePickerField
              disabled
              className="form-control"
              label="Year:"
              type="year"
              showYearPicker
              selected={editedRecord?.year ?? new Date()}
              dateFormat="yyyy"
              onChange={(year) => handleFieldChange("year", year)}
            />
          </div>
          <div className="row">
            <Form.Group className="form-group col-md-4" controlId="monthCycle">
              <Form.Label>Quarter:</Form.Label>
              <Form.Control
                // disabled
                as="select"
                value={editedRecord.monthCycle}
                className={showValidation.monthCycleError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("monthCycle", e.target.value)
                }
              >
                <option value="Jan-Mar">Jan-Mar</option>
                <option value="Apr-Jun">Apr-Jun</option>
                <option value="July-Sep">July-Sep</option>
                <option value="Oct-Dec">Oct-Dec</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form-group col-md-4" controlId="security">
              <Form.Label>Security:</Form.Label>
              <Form.Control
                as="select"
                value={editedRecord.security}
                className={showValidation.securityError ? "invalid" : ""}
                onChange={(e) => handleFieldChange("security", e.target.value)}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="N/A">N/A</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form-group col-md-4" controlId="performance">
              <Form.Label>Performance:</Form.Label>
              <Form.Control
                as="select"
                value={editedRecord.performance}
                className={showValidation.performanceError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("performance", e.target.value)
                }
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="N/A">N/A</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="form-group col-md-4" controlId="bugsPerHour">
              <Form.Label>Bugs Per Hour:</Form.Label>
              <Form.Control
                type="bugsPerHour"
                value={editedRecord.bugsPerHour}
                className={showValidation.bugsPerHourError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("bugsPerHour", e.target.value)
                }
              />
            </Form.Group>
            <Form.Group
              className="form-group col-md-4"
              controlId="defectDensity"
            >
              <Form.Label>Defect Density:</Form.Label>
              <Form.Control
                as="select"
                value={editedRecord.defectDensity}
                className={showValidation.defectDensityError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("defectDensity", e.target.value)
                }
              >
                <option value="High">High</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="N/A">N/A</option>
              </Form.Control>
            </Form.Group>
            <Form.Group
              className="form-group col-md-4"
              controlId="reviewerComment"
            >
              <Form.Label>Reviewer Comment:</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                value={editedRecord.reviewerComment}
                className={showValidation.reviewerCommentError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("reviewerComment", e.target.value)
                }
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
        <Button variant="secondary" onClick={onUpdate}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateQualityOfDevelopment;
