import React from "react";
import Select from "react-select";

const SelectList = ({
  label,
  id,
  placeholder,
  value,
  onChange,
  options,
  ErrorStyle,
  design,
  defaultValue,
  ...moreProps
}) => {
  if (!Array.isArray(options)) {
    options = [];
  }

  const isArrayOfStrings = options.every(
    (option) => typeof option === "string"
  );

  const transformedOptions = isArrayOfStrings
    ? options.map((option) => ({ value: option, label: option }))
    : options;

  return (
    <div className={`form-group col-md-4 ${design}`}>
      <label htmlFor={id}>{label}</label>
      <Select
        id={id}
        className={ErrorStyle}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        options={transformedOptions}
        {...moreProps}
        required
      />
    </div>
  );
};

export default SelectList;

// Other Possible props:

// isMulti - allow the user to select multiple values
// isSearchable - allow the user to search for matching options
// name - generate an HTML input with this name, containing the current value
// onChange - subscribe to change events
// options - specify the options the user can select from
// placeholder - change the text displayed when no option is selected
// noOptionsMessage - ({ inputValue: string }) => string | null - Text to display when there are no options
// value - control the current value
