import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { apiEndPoints } from "../../constants/apiEndPoints";
import DatePickerField from "../../Components/DatePickerField";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateArchitectureApproval = ({ record, onUpdate, onUpdateFunction }) => {
  const [editedRecord, setEditedRecord] = useState({
    ...record,
    year: new Date(record.year.toString()),
  });

  const [showValidation, setShowValidation] = useState({
    monthCycleError: "",
    architectureError: "",
    approvedError: "",
    versionUpdateError: "",
    commentError: "",
  });

  const handleFieldChange = (fieldName, value) => {
    const updatedRecord = { ...editedRecord };
    if (fieldName === "dateformat") {
      updatedRecord.date = formatDateToYYYYMMDD(value);
    } else {
      updatedRecord[fieldName] = value;
    }

    const newValidationState = { ...showValidation };

    if (fieldName === "monthCycle") {
      newValidationState.monthCycleError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "architecture") {
      newValidationState.architectureError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "approved") {
      newValidationState.approvedError = value ? "" : "This field is required.";
    } else if (fieldName === "versionUpdate") {
      newValidationState.versionUpdateError = value
        ? ""
        : "This field is required.";
    } else if (fieldName === "comment") {
      newValidationState.commentError = value ? "" : "This field is required.";
    }
    setShowValidation(newValidationState);
    setEditedRecord(updatedRecord);
  };

  const formatDateToYYYYMMDD = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleUpdate = async () => {
    const isFormValid = Object.values(showValidation).every(
      (error) => error === ""
    );
    if (!isFormValid) {
      return;
    }
    try {
      const employeeName = sessionStorage.getItem("employeeName");
      const response = await axios.put(
        apiEndPoints.updateArchitectureApproval,
        {
          ...editedRecord,
          year: editedRecord?.year.getFullYear(),
          username: employeeName,
        }
      );
      if (response.status === 200) {
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Adjust as needed
        });
        onUpdateFunction();
        onUpdate();
      }
      onUpdate();
    } catch (error) {
      // Show an error notification
      toast.error("Error updating record. Please try again later", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // Adjust as needed
      });
    }
  };

  const handleOnChange = (date) => {
    setEditedRecord((state) => ({
      ...state,
      date: moment(date).format("YYYY-MM-DD"),
    }));
  };

  return (
    <Modal
      className="modal fade bd-example-modal-lg"
      show={true}
      onHide={onUpdate}
      centered
      size="lg"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title>Update Architecture Approval</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Form style={{ width: "100%" }}>
          <div className="row">
            <Form.Group
              className="form-group col-md-4"
              controlId="departmentName"
            >
              <Form.Label>Department Name:</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={editedRecord.departmentName}
                onChange={(e) =>
                  handleFieldChange("departmentName", e.target.value)
                }
              />
            </Form.Group>
            <Form.Group
              className="form-group col-md-4"
              controlId="azureProjectName"
            >
              <Form.Label>Project Name:</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={editedRecord.azureProjectName}
                onChange={(e) =>
                  handleFieldChange("azureProjectName", e.target.value)
                }
              />
            </Form.Group>
            <DatePickerField
              className="form-control"
              label="Year:"
              disabled
              type="year"
              showYearPicker
              selected={editedRecord?.year ?? new Date()}
              dateFormat="yyyy"
              onChange={(year) => handleFieldChange("year", year)}
            />
          </div>
          <div className="row">
            <Form.Group className="form-group col-md-4" controlId="monthCycle">
              <Form.Label>Quarter:</Form.Label>
              <Form.Control
                // disabled
                as="select"
                value={editedRecord.monthCycle}
                className={showValidation.monthCycleError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("monthCycle", e.target.value)
                }
              >
                <option value="Jan-Mar">Jan-Mar</option>
                <option value="Apr-Jun">Apr-Jun</option>
                <option value="July-Sep">July-Sep</option>
                <option value="Oct-Dec">Oct-Dec</option>
              </Form.Control>
            </Form.Group>
            <Form.Group
              className="form-group col-md-4"
              controlId="architecture"
            >
              <Form.Label>Architecture:</Form.Label>
              <Form.Control
                as="select"
                value={editedRecord.architecture}
                className={showValidation.architectureError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("architecture", e.target.value)
                }
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">NA</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form-group col-md-4" controlId="approved">
              <Form.Label>Approved:</Form.Label>
              <Form.Control
                as="select"
                value={editedRecord.approved}
                className={showValidation.approvedError ? "invalid" : ""}
                onChange={(e) => handleFieldChange("approved", e.target.value)}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">NA</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="row">
            <DatePickerField
              className="form-control"
              label="Date:"
              onChange={(date) => handleOnChange(date)}
              value={editedRecord.date}
              dateFormat="yyyy-mm-dd"
            />
            <Form.Group
              className="form-group col-md-4"
              controlId="versionUpdate"
            >
              <Form.Label>Version Update:</Form.Label>
              <Form.Control
                as="select"
                value={editedRecord.versionUpdate}
                className={showValidation.versionUpdateError ? "invalid" : ""}
                onChange={(e) =>
                  handleFieldChange("versionUpdate", e.target.value)
                }
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">NA</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="form-group col-md-4" controlId="comment">
              <Form.Label>Comment:</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                value={editedRecord.comment}
                className={showValidation.commentError ? "invalid" : ""}
                onChange={(e) => handleFieldChange("comment", e.target.value)}
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
        <Button variant="secondary" onClick={onUpdate}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateArchitectureApproval;
