import React, { useState, useEffect } from "react";
import SelectList from "../../Components/Select";
import { callAPI } from "../../utils/callAPI";
import axios from "axios";
import { apiEndPoints } from "../../constants/apiEndPoints";
import DatePickerField from "../../Components/DatePickerField";
import TextArea from "../../Components/TextArea";
import "../Pages.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialProjectData = {
  departmentName: "",
  azureProjectName: "",
  // year: new Date(),
  // monthCycle: "",
  monthCycle: { value: "Oct-Dec", label: "Oct-Dec" },
  year: new Date(2023, 0, 1),
  securityTest: "",
  performanceTest: "",
  automationScriptDeveloped: { value: "No", label: "No" },
  compliance: "",
  comment: "",
};

const FormAutomationSecurity = (props) => {
  const [projectData, setProjectData] = useState(initialProjectData);
  const [showValidation, setShowValidation] = useState({
    departmentNameError: null,
    azureProjectNameError: null,
    // monthCycleError: null,
    securityTestError: null,
    performanceTestError: null,
    // automationScriptDevelopedError: null,
    complianceError: null,
    commentError: null,
  });
  const [masterData, setMasterData] = useState({
    departments: [],
  });
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const allData = await axios.all([
          callAPI("GET", apiEndPoints.getDeptName),
        ]);
        if (allData && allData.length > 0) {
          const departments = allData[0].data.data;
          departments.forEach((item) => {
            item.value = item.departmentId;
            item.label = item.departmentName;
            delete item.departmentId;
            delete item.departmentName;
          });
          setMasterData({
            ...{ departments },
          });
        }
      } catch (error) {}
    };
    getDepartments();
    props.fetchAutomationSecurity();
  }, []);

  const handleProjectData = (val, key) => {
    setProjectData((previousData) => {
      return {
        ...previousData,
        [key]: val,
      };
    });
    setShowValidation((previousData) => {
      return {
        ...previousData,
        [`${key}Error`]: val ? false : true,
      };
    });
  };

  useEffect(() => {
    const getProjects = async () => {
      let projectList = [];
      if (projectData.departmentName) {
        const departmentName = projectData.departmentName.label;
        try {
          const resp = await callAPI(
            "GET",
            apiEndPoints.getProjName + "?departmentName=" + departmentName
          );
          projectList = resp.data.data;
          projectList.forEach((item) => {
            item.value = item.azureProjectId;
            item.label = item.azureProjectName;
            delete item.azureProjectId;
            delete item.azureProjectName;
            delete item.department;
          });
        } catch (err) {}
      }
      setProjectList(projectList);
    };
    getProjects();
  }, [projectData.departmentName]);

  const clearFormFields = () => {
    setProjectData(initialProjectData);
    setShowValidation(false);
  };
  const clearFormFieldsOnAdd = () => {
    setProjectData({
      departmentName: projectData.departmentName,
      azureProjectName: "",
      monthCycle: projectData.monthCycle,
      year: new Date(2023, 0, 1),
      securityTest: "",
      performanceTest: "",
      automationScriptDeveloped: projectData.automationScriptDeveloped,
      compliance: "",
      comment: "",
    });

    setShowValidation((prev) => {
      return {
        ...prev,
        azureProjectNameError: null,
        securityTestError: null,
        performanceTestError: null,
        // automationScriptDevelopedError: null,
        complianceError: null,
        commentError: null,
      };
    });
  };

  const validationHandler = () => {
    const data = Object.values(showValidation).every((item) => item === false);
    const data1 = { ...projectData };
    const data2 = { ...showValidation };

    for (const key in data1) {
      if (data1[key] === "") {
        data2[`${key}Error`] = true;
      }
    }
    setShowValidation(data2);
    return data;
  };

  const handleSubmit = async () => {
    if (validationHandler() === false) {
      return false;
    }
    try {
      const employeeName = sessionStorage.getItem("employeeName");
      const response = await axios.post(
        apiEndPoints.addAutomationSecurityAndPerformance,
        {
          departmentId: projectData.departmentName.value,
          azureProjectId: projectData.azureProjectName.value,
          year: projectData.year.getFullYear(),
          monthCycle: projectData.monthCycle.value,
          securityTest: projectData.securityTest.value,
          performanceTest: projectData.performanceTest.value,
          automationScriptDeveloped:
            projectData.automationScriptDeveloped.value,
          compliance: projectData.compliance.value,
          comment: projectData.comment,
          username: employeeName,
        }
      );
      // clearFormFields();
      clearFormFieldsOnAdd();
      // document.getElementById("exampleModalLong2").click();
      props.fetchAutomationSecurity();
      toast.success("Form submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const errorMessage = error.response.data.message;
        alert(`${errorMessage}`);
      } else {
        console.error("Error submission", error);
      }
    }
  };

  return (
    <form
      className="modal fade bd-example-modal-lg"
      id="exampleModalLong2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Automation, Security And Performance
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearFormFields();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.departmentNameError ? "invalid" : ""}
              label="Department Name"
              id="deptName"
              placeholder="Department Name"
              value={projectData.departmentName}
              onChange={(value, e) => {
                handleProjectData(value, "departmentName");
              }}
              options={masterData.departments}
              isSearchable={true}
            />
            <SelectList
              ErrorStyle={showValidation.azureProjectNameError ? "invalid" : ""}
              label="Project Name"
              id="Project"
              placeholder="Project Name"
              value={projectData.azureProjectName}
              onChange={(value, e) => {
                handleProjectData(value, "azureProjectName");
              }}
              options={projectList}
              isSearchable={true}
            />
            <DatePickerField
              id="projYear"
              label="Year"
              showYearPicker
              dateFormat="yyyy"
              placeholderText="Select Year"
              selected={projectData.year}
              onChange={(date, evt) => {
                handleProjectData(date, "year");
              }}
            />
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={showValidation.monthCycleError ? "invalid" : ""}
              label="Quarter"
              id="Quarter"
              placeholder="Quarter"
              options={["Jan-Mar", "Apr-Jun", "July-Sep", "Oct-Dec"]}
              defaultValue={{ value: "Oct-Dec", label: "Oct-Dec" }}
              value={projectData.monthCycle}
              onChange={(value) => handleProjectData(value, "monthCycle")}
            />
            <SelectList
              ErrorStyle={showValidation.securityTestError ? "invalid" : ""}
              label="Security Test"
              id="securityTest"
              options={["Yes", "Partial Testing", "No", "N/A"]}
              value={projectData.securityTest}
              onChange={(value, e) => handleProjectData(value, "securityTest")}
            />
            <SelectList
              ErrorStyle={showValidation.performanceTestError ? "invalid" : ""}
              label="Performance Test"
              id="performanceTest"
              options={["Yes", "Partial Testing", "No", "N/A"]}
              value={projectData.performanceTest}
              onChange={(value, e) =>
                handleProjectData(value, "performanceTest")
              }
            />
          </div>
          <div className="modal-body">
            <SelectList
              ErrorStyle={
                showValidation.automationScriptDevelopedError ? "invalid" : ""
              }
              label="Automation Script Developed"
              id="automationScriptDeveloped"
              options={["Yes", "No", "N/A"]}
              defaultValue={{ value: "No", label: "No" }}
              value={projectData.automationScriptDeveloped}
              onChange={(value, e) =>
                handleProjectData(value, "automationScriptDeveloped")
              }
            />
            <SelectList
              ErrorStyle={showValidation.complianceError ? "invalid" : ""}
              label="Compliance"
              id="compliance"
              options={["Yes", "No", "N/A"]}
              value={projectData.compliance}
              onChange={(value, e) => handleProjectData(value, "compliance")}
            />
            <TextArea
              ErrorStyle={showValidation.commentError ? "invalid" : ""}
              label="Comment"
              id="comment"
              rows={1}
              cols={20}
              value={projectData.comment}
              onChange={(e) => handleProjectData(e.target.value, "comment")}
            />
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleSubmit} className="btn-Add">
              Add
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                clearFormFields();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormAutomationSecurity;
