import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Table, Spin } from "antd";
import "react-toastify/dist/ReactToastify.css";
import UpdateCustomerSatisfaction from "./UpdateCustomerSatisfaction";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import "../Pages.css";
import DeleteCmfModal from "../../Components/DeleteCmfModal/DeleteCmfModal";

const TableCustomerSatisfaction = (props) => {
	const [editingRecord, setEditingRecord] = useState(null);
	const [deleteModalData, setDeleteModalData] = useState({
		show: false,
		id: "",
	});
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
	});

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		props
			.fetchCustomerSatisfaction(pagination.current, pagination.pageSize)
			.then(() => {
				setLoading(false);
			});
	}, [props.searchParams]);

	const handleEdit = (record) => {
		setEditingRecord(record);
		setIsModalVisible(true);
	};

	const handleTableChange = (pagination) => {
		setPagination(pagination);
	};

	const onEdit = (record) => {
		setEditingRecord(record);
	};

	const onDelete = async (id) => {
		props.onDeleteFunction(id);
	};

	const columns = [
		{
			className: "table_heading",
			title: "Department",
			dataIndex: "departmentName",
			width: 120,
		},
		{
			title: "Project Name",
			dataIndex: "azureProjectName",
			width: 100,
		},
		{
			title: "Year",
			dataIndex: "year",
			width: 70,
		},
		{
			title: "Quarter",
			dataIndex: "monthCycle",
			width: 90,
		},
		{
			title: "Date",
			dataIndex: "date",
			width: 100,
		},
		{
			title: "Satisfaction Level",
			dataIndex: "satisfactionLevel",
			width: 100,
		},
		{
			title: "Referance Mail",
			dataIndex: "referenceMail",
			width: 80,
		},
		{
			title: "Comment",
			dataIndex: "comment",
			width: 100,
			render: (text, record) => (
				<div
					style={{
						maxHeight: "60px",
						overflowY: "auto",
						wordWrap: "break-word",
					}}>
					{text}
				</div>
			),
		},
		{
			title: "Submitted By",
			dataIndex: "username",
			width: 90,
		},
		{
			title: "Action",
			width: 80,
			render: (data, record) => {
				return (
					<span className="Action-button">
						<EditTwoTone
							twoToneColor="#00ff00"
							onClick={() => onEdit(record)}
						/>
						
						&nbsp;&nbsp;
						<DeleteTwoTone
							twoToneColor="#ff0000"
							onClick={() => setDeleteModalData({ show: true, id: data.id })}
						/>
					</span>
				);
			},
		},
	];
	return (
		<div className="table_main">
			<div className="table_container">
				<Spin spinning={loading}>
					<DeleteCmfModal
						onClick={() => onDelete(deleteModalData?.id)}
						visible={deleteModalData?.show}
						onCancel={() => setDeleteModalData({ show: false, id: "" })}
					/>
					<Table
						className="table_main table-striped "
						columns={columns.map((column) => ({ ...column, className: "" }))}
						dataSource={props.customerSatisfaction}
						pagination={pagination}
						onChange={handleTableChange}
						scroll={{
							x: 1200,
							// y: 500,
						}}
					/>
				</Spin>
				<div
					className={`modal fade bd-example-modal-lg ${
						isModalVisible ? "show" : ""
					}`}
					tabIndex="-1"
					role="dialog"
					style={{ display: isModalVisible ? "block" : "none" }}>
					<div className="modal-dialog modal-lg">
						<div className="modal-content ">
							<div className="modal-header">
								<h5 className="modal-title">Update Customer Satisfaction</h5>
								<button
									type="button"
									className="btn btn-secondary"
									onClick={() => (
										props.setCustomerSatisfaction({
											pageNumber: 1,
											departmentName: "",
											project: "",
											monthCycle: "",
											year: "",
										}),
										props.fetchCustomerSatisfaction()
									)}>
									Clear
								</button>
							</div>
							<div className="modal-body">
								{editingRecord && (
									<UpdateCustomerSatisfaction
										record={editingRecord}
										onUpdate={() => {
											setEditingRecord(false);
										}}
										onUpdateFunction={props.onUpdateFunction}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableCustomerSatisfaction;
