import React from "react";
import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const TextInputField = ({
  label,
  id,
  placeholder,
  value,
  onChange,
  disabled,
  ErrorStyle,
  design,
  loading,
}) => {
  return (
    <div className={`form-group col-md-4 ${design}`}>
      <label htmlFor={id}>{label}</label>
      {loading ? (
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Input
            type="text"
            className={`form-control ${ErrorStyle}`}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </Spin>
      ) : (
        <Input
          type="text"
          className={`form-control ${ErrorStyle}`}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default TextInputField;
