import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../Pages.css";
import "react-toastify/dist/ReactToastify.css";
import { Table, Spin } from "antd";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import UpdateProjectPlan from "./UpdateProjectPlan";
import DeleteCmfModal from "../../Components/DeleteCmfModal/DeleteCmfModal";

const ProjectPlanTable = (props) => {
	const [editingRecord, setEditingRecord] = useState(null);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
	});
	const [deleteModalData, setDeleteModalData] = useState({
		show: false,
		id: "",
	});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		props.fetchProjectPlan(pagination.current, pagination.pageSize);
		setLoading(false);
	}, [props.searchParams]);

	const handleEdit = (record) => {
		setEditingRecord(record);
		setIsModalVisible(true);
	};

	const handleTableChange = (pagination) => {
		setPagination(pagination);
	};

	const onEdit = (record) => {
		setEditingRecord(record);
	};

	const onDelete = async (id) => {
		props.onDeleteFunction(id);
	};

	const columns = [
		{
			className: "table_heading",
			title: "Department",
			dataIndex: "departmentName",
			width: 190,
		},
		{
			title: "Project Name",
			dataIndex: "azureProjectName",
			width: 120,
		},
		{
			title: "Year",
			dataIndex: "year",
			width: 70,
		},
		{
			title: "Quarter",
			dataIndex: "monthCycle",
			width: 90,
		},

		{
			title: "Plan",
			dataIndex: "plann",
			width: 70,
		},
		{
			title: "Planned Date",
			dataIndex: "plannedDate",
			width: 120,
		},
		{
			title: "Version",
			dataIndex: "version",
			width: 80,
		},
		{
			title: "Last Update",
			dataIndex: "lastUpdate",
			width: 100,
		},
		{
			title: "Last Version Update",
			dataIndex: "lastVersionUpdate",
			width: 160,
		},

		{
			title: "Comment",
			dataIndex: "comment",
			width: 180,
			render: (text, record) => (
				<div
					style={{
						maxHeight: "80px",
						overflowY: "auto",
						wordWrap: "break-word",
					}}>
					{text}
				</div>
			),
		},
		{
			title: "Submitted By",
			dataIndex: "username",
			width: 90,
		},
		{
			title: "Action",
			width: 80,
			render: (data, record) => (
				<span className="Action-button">
					<EditTwoTone twoToneColor="#00ff00" onClick={() => onEdit(record)} />
					&nbsp;&nbsp;
					<DeleteTwoTone
						twoToneColor="#ff0000"
						onClick={() => setDeleteModalData({ show: true, id: data.id })}
					/>
				</span>
			),
		},
	];

	return (
		<div className="table_main">
			<div className="table_container">
				<Spin spinning={loading}>
					<DeleteCmfModal
						onClick={() => onDelete(deleteModalData?.id)}
						visible={deleteModalData?.show}
						onCancel={() => setDeleteModalData({ show: false, id: "" })}
					/>
					<Table
						className="table_main"
						columns={columns.map((column) => ({ ...column, className: "" }))}
						dataSource={props.projectPlan}
						pagination={pagination}
						onChange={handleTableChange}
						scroll={{
							x: 1200,
							// y: 500,
						}}
					/>
				</Spin>
				<div
					className={`modal fade bd-example-modal-lg ${
						isModalVisible ? "show" : ""
					}`}
					tabIndex="-1"
					role="dialog"
					style={{ display: isModalVisible ? "block" : "none" }}>
					<div className="modal-dialog modal-lg">
						<div className="modal-content ">
							<div className="modal-header">
								<h5 className="modal-title">Update Project Plan</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => {
										setIsModalVisible(false);
										setEditingRecord(null);
									}}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{editingRecord && (
									<UpdateProjectPlan
										record={editingRecord}
										onUpdate={() => {
											setEditingRecord(false);
										}}
										onUpdateFunction={props.onUpdateFunction}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectPlanTable;
